const colors = {
  blue50: '#98BAFF',
  blue100: '#83ACFF',
  blue200: '#6E9EFF',
  blue300: '#5991FF',
  blue400: '#4583FF',
  blue500: '#3075FF',
  blue600: '#2B69E6',
  blue700: '#265ECC',
  blue800: '#2252B3',
  blue900: '#1D4699',

  ancientBlue50: '#98F9F9',
  ancientBlue100: '#83F7F7',
  ancientBlue200: '#6EF6F6',
  ancientBlue300: '#59F5F5',
  ancientBlue400: '#45F3F3',
  ancientBlue500: '#30F2F2',
  ancientBlue600: '#2BDADA',
  ancientBlue700: '#26C2C2',
  ancientBlue800: '#22A9A9',
  ancientBlue900: '#1D9191',

  green50: '#B0F9E1',
  green100: '#A0F7DA',
  green200: '#90F6D4',
  green300: '#81F5CE',
  green400: '#71F3C8',
  green500: '#61F2C2',
  green600: '#57DAAF',
  green700: '#4EC29B',
  green800: '#44A988',
  green900: '#3A9174',

  yellow50: '#FFFF80',
  yellow100: '#FFFF66',
  yellow200: '#FFFF4D',
  yellow300: '#FFFF33',
  yellow400: '#FFFF1A',
  yellow500: '#FFFF00',
  yellow600: '#E6E600',
  yellow700: '#CCCC00',
  yellow800: '#B3B300',
  yellow900: '#999900',

  red50: '#FFB1BC',
  red100: '#FFA1AF',
  red200: '#FF91A1',
  red300: '#FF8194',
  red400: '#FF7286',
  red500: '#FF6279',
  red600: '#E6586D',
  red700: '#CC4E61',
  red800: '#B34555',
  red900: '#993B49',

  dark50: '#82868C',
  dark100: '#696D75',
  dark200: '#50555E',
  dark300: '#373D47',
  dark400: '#1E2430',
  dark500: '#050C19',
  dark600: '#050B17',
  dark700: '#040A14',
  dark800: '#040812',
  dark900: '#03070F',

  light50: '#FBFCFF',
  light100: '#F8FAFF',
  light200: '#F6F9FF',
  light300: '#F5F8FF',
  light400: '#F2F6FF',
  light500: '#F0F5FF',
  light600: '#D8DDE6',
  light700: '#C0C4CC',
  light800: '#A8ACB3',
  light900: '#909399',

  // others
  transparent: 'transparent',
  white: '#FFFFFF',
};

const namedColors = {
  text: colors.dark500,
  primaryText: colors.blue500,
  secondaryText: colors.dark300,
  // primary button
  primaryButtonBackground: colors.blue500,
  primaryButtonHover: colors.blue600,
  primaryButtonLabel: colors.white,
  // secondary button
  secondaryButtonBackground: colors.light300,
  secondaryButtonHover: colors.light500,
  secondaryButtonLabel: colors.blue500,
  // danger button
  dangerButtonBackground: colors.red500,
  dangerButtonHover: colors.red600,
  dangerButtonLabel: colors.white,

  background: colors.light500,
  disabled: colors.light300,

  inputBackground: colors.light300,
  inputDisabledBackground: colors.light600,
  inputHoverBackground: colors.white,
  inputHoverBoxShadow: '0 0 0 2px rgb(48 117 255 / 50%)',

  disabledButtonBackground: colors.light600,
  disabledButtonLabel: colors.dark50,

  iconButtonBackground: colors.transparent,
  iconButtonLabelDisabled: colors.light800,

  iconButtonLabel: colors.dark500,
  iconButtonHover: colors.light100,

  hyperlinkText: colors.blue600,
  hyperlinkTextHover: colors.blue700,

  warning: colors.yellow500,
  warningBackground: colors.light50,
  error: colors.red500,
  errorBackground: colors.light50,

  border: colors.light400,
};

const allColors = {
  ...colors,
  ...namedColors,
};

export default allColors;
