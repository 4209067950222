import { Moment } from 'moment';
import { BackgroundCheckRequestStepsEnum } from '../../../api_types/enums';
import React from 'react';

export enum BackgroundCheckPaymentStatusEnum {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
}

export enum BackgroundCheckRequestStatusEnum {
  PENDING = 'pending',
  IDENTITY_VERIFICATION_IN_PROGRESS = 'identity_verification_in_progress',
  IDENTITY_VERIFICATION_COMPLETED = 'identity_verification_completed',
  COMPLETED = 'completed',
}

export type BackgroundCheckRequestPreviousRecordsType = {
  offense: string;
  dateOfSentence: Moment | string | null;
  courtLocation: string;
  description: string;
};

export type BackgroundCheckRequestType = {
  id?: string | null;
  applicationReferenceId?: string | null;
  reason?: string | null;
  criminalRecord?: boolean;
  previousRecords?: Array<BackgroundCheckRequestPreviousRecordsType>;
  consent?: boolean;
  completed?: boolean;
  status?: BackgroundCheckRequestStatusEnum;
  paymentStatus?: BackgroundCheckPaymentStatusEnum;
  currentStep?: BackgroundCheckRequestStepsEnum;
  reportUrl?: string;
};

export type StepsContextType = [
  BackgroundCheckRequestStepsEnum[],
  React.Dispatch<React.SetStateAction<BackgroundCheckRequestStepsEnum[]>>,
  BackgroundCheckRequestStepsEnum | undefined,
  React.Dispatch<React.SetStateAction<BackgroundCheckRequestStepsEnum>>,
  BackgroundCheckRequestType,
  React.Dispatch<React.SetStateAction<BackgroundCheckRequestType>>,
  BackgroundCheckRequestStepsEnum[],
  React.Dispatch<React.SetStateAction<BackgroundCheckRequestStepsEnum[]>>
];

export const DEFAULT_BACKGROUND_CHECK_REQUEST = {
  id: '',
  status: BackgroundCheckRequestStatusEnum.PENDING,
  paymentStatus: BackgroundCheckPaymentStatusEnum.PENDING,
  completed: false,
};
