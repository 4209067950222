import styled from 'styled-components';

export const CardsBrandWrapper = styled.span`
  position: absolute;
  top: 36px;
  right: 15px;
  > svg {
    width: 35px;
    height: 35px;
  }
`;
