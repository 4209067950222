import React from 'react';

import { AuthFormContent as ResetPasswordFormContent } from '../styled';
import { ResetPasswordButtonWrapper } from './styled';

import { Col, Form, Row } from 'antd';
import { notification } from '../../../helpers';
import { Button } from 'ov-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormItemInput } from 'ov-components';
import useAxiosHttClient from '../../../config/http_client';

export const EditPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('reset_password_token');

  const [{ loading }, executeUpdatePassword] = useAxiosHttClient(
    {
      url: '/reset_password.json',
      method: 'put',
    },
    { manual: true }
  );

  const submitPasswordUpdate = async (values: any) => {
    try {
      await executeUpdatePassword({
        data: {
          user: {
            password: values.password,
            password_confirmation: values.confirm_password,
            reset_password_token: resetPasswordToken,
          },
        },
      });

      navigate('/login');
      notification.success({
        message: 'Your password has been updated successfully',
      });
    } catch (error) {
      if (
        error.response.data.errors &&
        error.response.data.errors.reset_password_token
      ) {
        notification.error({
          message: 'Reset password token is invalid',
        });
      } else {
        notification.error({
          message: 'Something went wrong.',
        });
      }
    }
  };

  return (
    <>
      <ResetPasswordFormContent>
        <h3>Update your password</h3>
      </ResetPasswordFormContent>
      <Form
        form={form}
        name="edit_password"
        layout={'vertical'}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={submitPasswordUpdate}
      >
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your new password!',
            },
          ]}
        >
          <FormItemInput variant="password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please enter your new password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <FormItemInput variant="password" />
        </Form.Item>
        <ResetPasswordButtonWrapper>
          <Row justify="space-between">
            <Col md={8} xs={24}>
              <Form.Item>
                <Button
                  variant="primary"
                  shape="circle"
                  disabled={loading}
                  block
                >
                  Update Password
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </ResetPasswordButtonWrapper>
      </Form>
    </>
  );
};
