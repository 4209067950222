import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'antd';
import {
  BackgroundCheckRequestActions,
  BackgroundCheckRequestConfirmButton,
  BackgroundCheckRequestPreviousLink,
  StepSection,
  StepSubTitle,
  StepTitle,
} from '../styled';
import {
  CriminalRecordRadio,
  CriminalRecordRadioButtons,
  CriminalRecordButtonCard,
  CriminalRecordButtonTitle,
  CriminalRecordButtonSubTitle,
  CriminalRecordButtonCardIcon,
  PreviousRecordFormActions,
  PreviousRecordFormInputWrapper,
} from './styled';
import { Button, FormItemInput } from 'ov-components';
import { Icon } from '../../../../stories/atoms/Icon';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import { useNavigate } from 'react-router-dom';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { notification } from '../../../../helpers';
import { uniq } from 'lodash';
import moment from 'moment';
import { BackgroundCheckPreviousRecordsFormValues } from './types';

export const SelfDeclaration: React.FC = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const navigate = useNavigate();
  const [
    steps,
    setSteps,
    ,
    setActiveStep,
    backgroundCheckRequest,
    setBackgroundCheckRequest,
  ] = useBackgroundCheckRequestSteps();
  const criminalRecordInitialValue =
    backgroundCheckRequest.criminalRecord === undefined
      ? false
      : backgroundCheckRequest.criminalRecord;
  const [criminalRecord, setCriminalRecord] = useState(
    criminalRecordInitialValue
  );
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [previousRecordForm] = Form.useForm();

  const previousRecordsInitialValues = [
    { offense: '', date_of_sentence: '', court_location: '', description: '' },
  ];
  const existingPreviousRecords = backgroundCheckRequest &&
  backgroundCheckRequest.previousRecords &&
  backgroundCheckRequest.previousRecords.map(function(record) {
    return {
      offense: record.offense,
      date_of_sentence: record.dateOfSentence,
      court_location: record.courtLocation,
      description: record.description,
    };
  });

  const [{ loading }, updateBackgroundCheckRequestApi] = useAxiosHttClient(
    {
      method: 'PUT',
      url: '/background_check',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const handleConfirmButtonDisabledOnFormValidation = () => {
    previousRecordForm
      .validateFields()
      .then(() => {
        setConfirmDisabled(false);
      })
      .catch(() => {
        setConfirmDisabled(true);
      });
  };

  useEffect(() => {
    if (criminalRecord) {
      handleConfirmButtonDisabledOnFormValidation();
    } else {
      setConfirmDisabled(false);
    }
  }, [criminalRecord]);

  const handleOnFormChange = (_, allFields) => {
    const hasErrors = allFields.some((field) => field.errors.length);
    setConfirmDisabled(hasErrors);
  };

  const handleRemovePreviousRecord = (removeField, field) => {
    removeField(field.name);
    if (field.name === 0) {
      setConfirmDisabled(true);
    } else {
      handleConfirmButtonDisabledOnFormValidation();
    }
  };

  const handleOnConfirm = () => {
    const onConfirm = async (
      previousRecords: Array<BackgroundCheckPreviousRecordsFormValues> = []
    ) => {
      try {
        const completedSteps = uniq([
          ...steps,
          BackgroundCheckRequestStepsEnum.Payment,
        ]);
        const currentStep = BackgroundCheckRequestStepsEnum.Payment;
        const data = {
          criminal_record: criminalRecord,
          completed_steps: completedSteps,
          current_step: currentStep,
          previous_records: previousRecords.map((record) => {
            return {
              ...record,
              date_of_sentence: moment(record.date_of_sentence).format(
                'YYYY-MM-DD'
              ),
            };
          }),
        };

        await updateBackgroundCheckRequestApi({ data });

        setBackgroundCheckRequest({
          ...backgroundCheckRequest,
          criminalRecord,
          previousRecords: previousRecords.map((record) => {
            return {
              offense: record.offense,
              dateOfSentence: record.date_of_sentence,
              courtLocation: record.court_location,
              description: record.description,
            };
          }),
        });
        setSteps(completedSteps);
        setActiveStep(currentStep);
        navigate(tabRoutes[currentStep]);
      } catch (error) {
        notification.error({ message: 'Something went wrong' });
      }
    };

    if (criminalRecord) {
      return previousRecordForm
        .validateFields()
        .then((values) => {
          onConfirm(values.previousRecords);
        })
        .catch(() => {
          setConfirmDisabled(true);
        });
    }

    return onConfirm([]);
  };

  return (
    <>
      <StepSection>
        <StepTitle>Do you have a criminal record?</StepTitle>
        <StepSubTitle>
          We’ll check CPIC and PIP databases and send results to the requester.
        </StepSubTitle>
        <CriminalRecordRadioButtons
          value={criminalRecord}
          onChange={(e) => setCriminalRecord(e.target.value)}
        >
          <CriminalRecordRadio.Button value={true}>
            <CriminalRecordButtonCard>
              <CriminalRecordButtonCardIcon
                name={'checkCircle'}
                variant={'extraLarge'}
              />
              <div>
                <CriminalRecordButtonTitle>Yes</CriminalRecordButtonTitle>
                <CriminalRecordButtonSubTitle>
                  I have a criminal record
                </CriminalRecordButtonSubTitle>
              </div>
            </CriminalRecordButtonCard>
          </CriminalRecordRadio.Button>
          <CriminalRecordRadio.Button value={false}>
            <CriminalRecordButtonCard>
              <CriminalRecordButtonCardIcon
                name={'close'}
                variant={'extraLarge'}
              />
              <div>
                <CriminalRecordButtonTitle>No</CriminalRecordButtonTitle>
                <CriminalRecordButtonSubTitle>
                  {"I don't have a criminal record"}
                </CriminalRecordButtonSubTitle>
              </div>
            </CriminalRecordButtonCard>
          </CriminalRecordRadio.Button>
        </CriminalRecordRadioButtons>
      </StepSection>
      <StepSection>
        {criminalRecord && (
          <Form
            form={previousRecordForm}
            name="previousRecordsForm"
            layout={'vertical'}
            autoComplete="off"
            initialValues={{
              previousRecords:
                existingPreviousRecords && existingPreviousRecords.length > 0
                  ? existingPreviousRecords
                  : previousRecordsInitialValues,
            }}
            onFieldsChange={handleOnFormChange}
          >
            <Form.List name="previousRecords">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key}>
                      <PreviousRecordFormInputWrapper>
                        <div>
                          <Row gutter={16} align="middle">
                            <Col md={12} xs={12}>
                              <Form.Item
                                {...field}
                                label="Offense"
                                name={[field.name, 'offense']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter offense!',
                                  },
                                ]}
                              >
                                <FormItemInput />
                              </Form.Item>
                            </Col>
                            <Col md={12} xs={12}>
                              <Form.Item
                                {...field}
                                label="Date of sentence"
                                name={[field.name, 'date_of_sentence']}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Please enter your date of sentence!',
                                  },
                                ]}
                              >
                                <FormItemInput variant="datepicker" />
                              </Form.Item>
                            </Col>
                            <Col md={12} xs={12}>
                              <Form.Item
                                {...field}
                                label="Court Location"
                                name={[field.name, 'court_location']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter count location!',
                                  },
                                ]}
                              >
                                <FormItemInput />
                              </Form.Item>
                            </Col>
                            <Col md={12} xs={12}>
                              <Form.Item
                                {...field}
                                label="Description"
                                name={[field.name, 'description']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter description!',
                                  },
                                ]}
                              >
                                <FormItemInput />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Icon
                            display={'flex'}
                            name={'close'}
                            variant={'large'}
                            onClick={() =>
                              handleRemovePreviousRecord(remove, field)
                            }
                          />
                        </div>
                      </PreviousRecordFormInputWrapper>
                    </div>
                  ))}

                  <Form.Item>
                    <PreviousRecordFormActions>
                      <Button
                        variant={'secondary'}
                        onClick={() => add()}
                        icon={'plus'}
                        iconVariant={'large'}
                        shape={'circle'}
                      >
                        Add Record
                      </Button>
                    </PreviousRecordFormActions>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        )}
      </StepSection>

      <BackgroundCheckRequestActions>
        <BackgroundCheckRequestPreviousLink
          to={tabRoutes[BackgroundCheckRequestStepsEnum.CandidateInformation]}
        >
          <Icon name={'left'} /> Go to previous
        </BackgroundCheckRequestPreviousLink>
        <BackgroundCheckRequestConfirmButton
          variant="primary"
          shape="circle"
          disabled={loading || confirmDisabled}
          onClick={handleOnConfirm}
        >
          Confirm and Continue
        </BackgroundCheckRequestConfirmButton>
      </BackgroundCheckRequestActions>
    </>
  );
};
