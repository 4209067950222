import React from 'react';
import {
  ThankYouWrapper,
  ThankYouContent,
  ThankYouLinks,
  ThankYouImage,
  ThankYouImageWrapper,
} from './styled';
import { Link } from 'react-router-dom';
import useAxiosHttClient from '../../../config/http_client';
import { notification } from '../../../helpers';
import { PageFullBodyStyle } from '../styled';

interface ThankYouProps {
  email: string;
}

const ThankYou: React.FC<ThankYouProps> = ({ email }) => {
  const [, executeResendConfirmation] = useAxiosHttClient(
    {
      url: '/resend_confirmation_instructions.json',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    },
    { manual: true }
  );

  const resendConfirmationInstructions = async () => {
    try {
      await executeResendConfirmation({
        data: {
          user: {
            email,
          },
        },
      });

      notification.success({
        message: 'Confirmation instructions sent',
      });
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
      });
    }
  };

  return (
    <>
      <PageFullBodyStyle />
      <ThankYouWrapper>
        <ThankYouImageWrapper>
          <ThankYouImage />
        </ThankYouImageWrapper>
        <ThankYouContent>
          <h1>Thank you for registering!</h1>
          <p>
            We’ve sent a confirmation e-mail to {email}. Please verify your
            account to continue.
          </p>
        </ThankYouContent>
        <ThankYouLinks>
          {"Didn't receive an e-mail? "}
          <Link to={''} onClick={resendConfirmationInstructions}>
            Resend
          </Link>
        </ThankYouLinks>
      </ThankYouWrapper>
    </>
  );
};

export default ThankYou;
