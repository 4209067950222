import React from 'react';

const LeftIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      d="M15.7,18.7c0.4-0.4,0.4-1.1,0-1.5L10.5,12l5.2-5.2c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0l-5.9,5.9
	C8.1,11.4,8,11.7,8,12c0,0.3,0.1,0.6,0.3,0.8l5.9,5.9C14.6,19.1,15.3,19.1,15.7,18.7z"
    />
  </svg>
);

export default LeftIcon;
