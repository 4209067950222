import React from 'react';

import { AuthFormContent as ResetPasswordFormContent } from '../styled';
import { ResetPasswordButtonWrapper } from './styled';

import { Col, Form, Row } from 'antd';
import { notification } from '../../../helpers';
import { Button } from 'ov-components';
import { Link, useNavigate } from 'react-router-dom';
import { FormItemInput } from 'ov-components';
import useAxiosHttClient from '../../../config/http_client';
import { capitalize } from 'lodash';
import { displayDeviseApiFormMessages } from '../../../helpers/error_messages_helpers';

export const ResetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [{ loading }, executeResetPassword] = useAxiosHttClient(
    {
      url: '/send_password_reset_instructions.json',
      method: 'post',
    },
    { manual: true }
  );

  const submitResetPassword = async (values: any) => {
    try {
      await executeResetPassword({
        data: {
          user: {
            email: values.email,
          },
        },
      });

      navigate('/login');
      notification.success({
        message: 'Reset password request sent successfully',
      });
    } catch (error) {
      displayDeviseApiFormMessages(error, form);
    }
  };

  return (
    <>
      <ResetPasswordFormContent>
        <h3>Reset your password</h3>
      </ResetPasswordFormContent>
      <Form
        form={form}
        name="reset_password"
        layout={'vertical'}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={submitResetPassword}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            {
              pattern: /^\S+@\S+\.\S+$/,
              message: 'Email is not valid!',
            },
          ]}
        >
          <FormItemInput />
        </Form.Item>
        <ResetPasswordButtonWrapper>
          <Row justify="space-between">
            <Col md={16} xs={24}>
              <Link to={'/login'}>Login to your account</Link>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item>
                <Button
                  variant="primary"
                  shape="circle"
                  disabled={loading}
                  block
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </ResetPasswordButtonWrapper>
      </Form>
    </>
  );
};
