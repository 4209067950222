import React from 'react';

const InstagramIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M18.6,7.1c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C18.1,5.9,18.6,6.4,18.6,7.1z
	 M17.1,12.3c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C14.9,7.4,17.1,9.6,17.1,12.3z M15.4,12.3
	c0-1.7-1.4-3.2-3.2-3.2c-1.7,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2C13.9,15.5,15.4,14.1,15.4,12.3z M22.1,16.3v-8
	c0-3.3-2.7-6-6-6h-8c-3.3,0-6,2.7-6,6v8c0,3.3,2.7,6,6,6h8C19.4,22.3,22.1,19.6,22.1,16.3z M16.1,4.2c2.3,0,4.1,1.9,4.1,4.1v8
	c0,2.3-1.9,4.1-4.1,4.1h-8c-2.3,0-4.1-1.9-4.1-4.1v-8c0-2.3,1.9-4.1,4.1-4.1H16.1z"
    />
  </svg>
);

export default InstagramIcon;
