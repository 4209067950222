import React, { memo } from 'react';
import { Input, InputProps } from '../../atoms/Input';
import { Form as AntdForm } from 'antd';

const FormItemInputComponent: React.FC<InputProps> = (props) => {
  const { status } = AntdForm.Item.useStatus();
  return <Input {...props} size={'large'} errorState={status} />;
};

export const FormItemInput = memo(FormItemInputComponent);
