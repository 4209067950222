import React, { ReactNode, useState } from 'react';
import { AccountContext, DEFAULT_ACCOUNT } from '../context';

interface ProviderProps {
  children?: ReactNode;
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [account, setAccount] = useState(DEFAULT_ACCOUNT);
  const [userId, setUserId] = useState('');
  const [userProfileId, setUserProfileId] = useState('');
  return (
    <AccountContext.Provider
      value={[
        account,
        setAccount,
        userId,
        setUserId,
        userProfileId,
        setUserProfileId,
      ]}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default Provider;
