import React from 'react';
import {
  FinishSuccessContainer,
  FinishSuccessBody,
  PageFullBodyStyle,
  PaymentSuccessIcon,
  FinishSuccessTitle,
  FinishSuccessMessage,
  StartNewBackgroundCheckButton,
} from './styled';
import { Text } from 'ov-components';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import {
  BackgroundCheckPaymentStatusEnum,
  BackgroundCheckRequestStatusEnum,
} from '../types';
import { useNavigate } from 'react-router-dom';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { BackgroundCheck } from '../../../../api_types';

export const Finish: React.FC = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const navigate = useNavigate();
  const [
    ,
    setSteps,
    ,
    setActiveStep,
    backgroundCheckRequest,
    setBackgroundCheckRequest,
  ] = useBackgroundCheckRequestSteps();

  const [
    { loading: restartBackgroundCheckApiLoading },
    restartBackgroundCheckApi,
  ] = useAxiosHttClient(
    {
      method: 'POST',
      url: '/background_check',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const restartNewBackgroundCheckRequest = async () => {
    const response = await restartBackgroundCheckApi();
    const backgroundCheckResponse: BackgroundCheck = response.data.data;
    setBackgroundCheckRequest({
      id: backgroundCheckResponse.id,
      paymentStatus: BackgroundCheckPaymentStatusEnum.PENDING,
      status: BackgroundCheckRequestStatusEnum.PENDING,
    });

    setSteps([]);
    setActiveStep(BackgroundCheckRequestStepsEnum.Reason);
    navigate(tabRoutes[BackgroundCheckRequestStepsEnum.Reason]);
  };

  const backgroundCheckCompleted =
    backgroundCheckRequest.completed && !!backgroundCheckRequest.reportUrl;

  return (
    <>
      <PageFullBodyStyle />
      <FinishSuccessContainer>
        <FinishSuccessBody>
          <Text center>
            <>
              <PaymentSuccessIcon name={'checkCircle'} fillColor={'green500'} />
              <FinishSuccessTitle>Submission Successful!</FinishSuccessTitle>
              <FinishSuccessMessage>
                {backgroundCheckCompleted && (
                  <p>
                    Access the full report{' '}
                    <a
                      target="_blank"
                      href={backgroundCheckRequest.reportUrl}
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                )}
                <p>
                  We will send the results to your email. Please let us know if
                  you have any questions at{' '}
                  <a href="mailto:verify@ontab.com">verify@ontab.com</a>.
                </p>

                {/* {backgroundCheckCompleted && ( */}
                <StartNewBackgroundCheckButton
                  shape={'circle'}
                  onClick={restartNewBackgroundCheckRequest}
                  loading={restartBackgroundCheckApiLoading}
                >
                  Start a new Background Check CTA
                </StartNewBackgroundCheckButton>
                {/* )} */}
              </FinishSuccessMessage>
            </>
          </Text>
        </FinishSuccessBody>
      </FinishSuccessContainer>
    </>
  );
};
