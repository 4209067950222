import styled, { createGlobalStyle } from 'styled-components';
import { colors, typography } from '../../../../stories/foundation';
import { Icon } from '../../../../stories/atoms/Icon';
import { mediaBreakPointDown } from '../../../../stories/breakpoints';
import { Button } from 'ov-components';

export const PageFullBodyStyle = createGlobalStyle`
  body {
    background-color: ${colors.light50};
  }
`;

export const FinishSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 130px 0 100px 0;
`;

export const FinishSuccessBody = styled.div`
  background-color: ${colors.white};
  border: 3px solid ${colors.light500};
  width: 42%;
  padding: 58px;
  border-radius: 8px;
  ${mediaBreakPointDown('xmd', `width: 80%;`)}
  ${mediaBreakPointDown('sm', `width: 90%;`)}
`;

export const PaymentSuccessIcon = styled(Icon)`
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
`;

export const FinishSuccessTitle = styled.h2`
  ${typography.h2}
`;

export const FinishSuccessMessage = styled.div`
  ${typography.p1}
`;

export const StartNewBackgroundCheckButton = styled(Button)`
  margin: 20px 0;
`;
