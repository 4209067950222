import { css } from 'styled-components';

const h1 = css`
  font-family: 'Manrope', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0px;
`;

const h2 = css`
  font-family: 'Manrope', serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.15px;
`;

const h3 = css`
  font-family: 'Manrope', serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.15px;
`;

const h4 = css`
  font-family: 'Manrope', serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const allCapsLarge = css`
  font-family: 'Manrope', serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const allCapsSmall = css`
  font-family: 'Manrope', serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const p1 = css`
  font-family: 'Manrope', serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
`;

const p1Regular = css`
  font-weight: 400;
  ${p1}
`;

const p1Medium = css`
  font-weight: 500;
  ${p1}
`;

const p1SemiBold = css`
  font-weight: 600;
  ${p1}
`;

const p1Bold = css`
  font-family: 'ManropeBold', serif;
  font-weight: 600;
  ${p1}
`;

const p2 = css`
  font-family: 'Manrope', serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
`;

const p2Regular = css`
  font-weight: 400;
  ${p2}
`;

const p2Medium = css`
  font-weight: 500;
  ${p2}
`;

const p2SemiBold = css`
  font-weight: 600;
  ${p2}
`;

const p2Bold = css`
  font-family: 'ManropeBold', serif;
  font-weight: 600;
  ${p2}
`;

const p3 = css`
  font-family: 'Manrope', serif;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
`;

const p3Regular = css`
  font-weight: 400;
  ${p3}
`;

const p3Medium = css`
  font-weight: 500;
  ${p3}
`;

const p3SemiBold = css`
  font-weight: 600;
  ${p3}
`;

const p3Bold = css`
  font-family: 'ManropeBold', serif;
  font-weight: 600;
  ${p3}
`;

export default {
  h1,
  h2,
  h3,
  h4,
  allCapsLarge,
  allCapsSmall,
  p1,
  p1Regular,
  p1Medium,
  p1SemiBold,
  p1Bold,
  p2,
  p2Regular,
  p2Medium,
  p2SemiBold,
  p2Bold,
  p3,
  p3Regular,
  p3Medium,
  p3SemiBold,
  p3Bold,
};
