import React, { memo } from 'react';
import { colors } from '../../foundation';
import * as icons from './icons';
import { IconName } from './icons';

export type IconColorName = keyof typeof colors;
export interface IconProps {
  className?: string;
  name: IconName;
  variant?: 'small' | 'medium' | 'large' | 'extraLarge' | 'extraExtraLarge';
  fillColor?: string;
  display?:
    | 'block'
    | 'flex'
    | 'inline-flex'
    | 'inline-block'
    | 'inline'
    | 'none';
  onClick?: (e?: React.MouseEvent<HTMLSpanElement>) => void;
  cursor?: string;
}

const variants = {
  small: '16px',
  medium: '24px',
  large: '32px',
  extraLarge: '48px',
  extraExtraLarge: '56px',
};

// We can add icons to /icons as we needed. We're utilizing MUI icons so
// installing the MUI icons package might be something we want to do but it
// looks like it requires installing MUI along with emotion which might add
// a bit of overhead and it's also causing some issues within webpack so
// we'll need to dedicate some dev time towards troubleshooting that if we
// want to go that route.

const IconComponent: React.FC<IconProps> = (props) => {
  const {
    className,
    name,
    variant,
    fillColor,
    display = 'inline-flex',
    cursor = 'initial',
  } = props;

  const BaseIcon = icons[name];
  const size = variant ? variants[variant] : undefined;
  const color = fillColor ? colors[fillColor] : undefined;

  const iconStyle = {
    display,
    height: size,
    width: size,
    color,
  };

  return (
    <span
      style={{
        'display': display,
        'cursor': props.onClick ? 'pointer' : cursor,
      }}
      onClick={props.onClick}
    >
      <BaseIcon className={className} style={iconStyle} />
    </span>
  );
};

export const Icon = memo(IconComponent);
