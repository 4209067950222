import React, { useEffect, useState } from 'react';
import {
  BackgroundCheckRequestConfirmButton,
  StepSection,
  StepTitle,
} from '../styled';
import {
  BackgroundCheckIDVerificationActions,
  VerifyIDVerificationContent,
} from './styled';
import { useBackgroundCheckRequestSteps } from '../index';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { displayApiErrors } from '../../../../helpers/error_messages_helpers';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'ov-components';
import { BackgroundCheck } from '../../../../api_types';

export const IdentityVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [, , , , backgroundCheckRequest, setBackgroundCheckRequest, ,] =
    useBackgroundCheckRequestSteps();
  const [verifyingIdVerification, setVerifyingIVerification] = useState(false);
  const [disabledIdVerificationLink, setDisabledIdVerificationLink] =
    useState(false);

  useEffect(() => {
    const verifyThirdPartyIdVerificationStatus = async () => {
      try {
        const response = await getBackgroundCheckApi();
        const backgroundCheck: BackgroundCheck = response && response.data && response.data.data;

        if (
          backgroundCheck.attributes.applicationCompleted ||
          backgroundCheck.attributes.idVerificationCompleted
        ) {
          setBackgroundCheckRequest({
            ...backgroundCheckRequest,
            status: backgroundCheck.attributes.status,
            completed: true,
          });
        } else {
          // Retry in two second
          await new Promise((resolve) => setTimeout(resolve, 2000));
          await verifyThirdPartyIdVerificationStatus();
        }
      } catch (error) {
        /* empty */
      }
    };

    if (
      backgroundCheckRequest.applicationReferenceId &&
      searchParams.get('reference_id') ===
        backgroundCheckRequest.applicationReferenceId &&
      !backgroundCheckRequest.completed
    ) {
      setVerifyingIVerification(true);
      verifyThirdPartyIdVerificationStatus();
    }
  }, [searchParams, backgroundCheckRequest]);

  const [, getBackgroundCheckApi] = useAxiosHttClient(
    {
      url: '/background_check',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const [
    { loading: loadingGetIdentityVerificationLinkApi },
    getIdentityVerificationLinkApi,
  ] = useAxiosHttClient(
    {
      url: '/background_check/verify/identity_verification_link',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const handleOnIdVerificationClick = async () => {
    try {
      setDisabledIdVerificationLink(true);
      const getIdentityVerificationLinkApiResponse =
        await getIdentityVerificationLinkApi();
      const idVerificationRedirectUrl =
        getIdentityVerificationLinkApiResponse.data.identity_verification_url;
      window.open(idVerificationRedirectUrl, '_self');
    } catch (error) {
      setDisabledIdVerificationLink(false);
      displayApiErrors(error);
    }
  };

  return (
    <>
      <StepSection>
        <StepTitle>
          {verifyingIdVerification ? (
            <>
              <Spinner size="medium" />
              <VerifyIDVerificationContent>
                This page may take a few minutes to update. If you have
                completed the ID verification process on your mobile device,
                please check your inbox in a few minutes for your report.
              </VerifyIDVerificationContent>
              <VerifyIDVerificationContent>
                If you do not receive a report within a few hours of completing
                this process, please email{' '}
                <a href="mailto:verify@ontab.com">verify@ontab.com</a>
              </VerifyIDVerificationContent>
            </>
          ) : (
            <>
              Please complete your ID verification. After you click on the
              relevant button, a new window will open and you can continue with
              your ID verification.
            </>
          )}
        </StepTitle>
      </StepSection>
      {!verifyingIdVerification && (
        <BackgroundCheckIDVerificationActions lastStep>
          <BackgroundCheckRequestConfirmButton
            variant="secondary"
            shape="circle"
            loading={loadingGetIdentityVerificationLinkApi}
            disabled={disabledIdVerificationLink}
            onClick={handleOnIdVerificationClick}
          >
            Click here to complete your ID Verification
          </BackgroundCheckRequestConfirmButton>
        </BackgroundCheckIDVerificationActions>
      )}
    </>
  );
};
