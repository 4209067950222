import React from 'react';
import {
  LandingBanner,
  LandingBannerImage,
  LandingBannerShape,
  LandingBannerContentWrapper,
  LandingBannerContentBody,
  LandingBannerContentTitle,
  LandingBannerContent,
  LandingBannerButton,
  LandingFeatures,
  LandingFeatureCard,
  LandingFeatureImage,
  LandingFeatureTitle,
  LandingFeatureContent,
  LandingPricing,
  LandingPricingImage,
  LandingPricingContentWrapper,
  LandingPricingContent,
  LandingPricingContentTitle,
  LandingPricingContentSubtitle,
  LandingPricingContentButtons,
  LandingPricingContentTitlePrice,
  LandingPricingContentTitlePriceProduct,
} from './styled';
import landingBannerShape from '../../../assets/images/landing_shape.png';
import landingBannerImage from '../../../assets/images/landing_image.png';
import fastImage from '../../../assets/images/fast.png';
import secureImage from '../../../assets/images/secure.png';
import affordableImage from '../../../assets/images/affordable.png';
import laptopImage from '../../../assets/images/laptop.jpeg';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'ov-components';

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <LandingBanner>
        <LandingBannerShape src={landingBannerShape} />
        <LandingBannerImage src={landingBannerImage} />
        <LandingBannerContentWrapper>
          <LandingBannerContentBody>
            <LandingBannerContentTitle>
              Background Checks
            </LandingBannerContentTitle>
            <LandingBannerContent>
              Frictionless, fast results for you and your business
            </LandingBannerContent>
          </LandingBannerContentBody>
          <LandingBannerButton
            shape="circle"
            onClick={() => navigate('/sign-up')}
          >
            Start Background Check
          </LandingBannerButton>
        </LandingBannerContentWrapper>
      </LandingBanner>
      <LandingFeatures>
        <LandingFeatureCard>
          <LandingFeatureImage src={fastImage} />

          <LandingFeatureTitle>
            Results in as little as 15 Minutes
          </LandingFeatureTitle>
          <LandingFeatureContent>
            {
              "We provide results in 15 minutes or more which may vary depending on the completeness of the applicant's information and delays through our providers."
            }
          </LandingFeatureContent>
        </LandingFeatureCard>
        <LandingFeatureCard>
          <LandingFeatureImage src={secureImage} />

          <LandingFeatureTitle>CPIC Police Results</LandingFeatureTitle>
          <LandingFeatureContent>
            Our technology connects data from trusted partners using Canadian
            Police Information Centre (CPIC) database administered by RCMP. This
            allows us to provide you with accurate reports.
          </LandingFeatureContent>
        </LandingFeatureCard>
        <LandingFeatureCard>
          <LandingFeatureImage src={affordableImage} />

          <LandingFeatureTitle>$49.90 Per Background Check</LandingFeatureTitle>
          <LandingFeatureContent>
            We offer affordable pricing. Only pay for the background checks you
            need. No additional fees.
          </LandingFeatureContent>
        </LandingFeatureCard>
      </LandingFeatures>
      {/* TODO: Uncomment the below code */}
      {/*<LandingPricing>*/}
      {/*  <LandingPricingImage src={laptopImage} />*/}
      {/*  <LandingPricingContentWrapper>*/}
      {/*    <LandingPricingContent>*/}
      {/*      <LandingPricingContentTitle color={'blue500'}>*/}
      {/*        <LandingPricingContentTitlePrice>$35</LandingPricingContentTitlePrice>*/}
      {/*        <LandingPricingContentTitlePriceProduct>/background check</LandingPricingContentTitlePriceProduct>*/}
      {/*      </LandingPricingContentTitle>*/}
      {/*      <LandingPricingContentSubtitle>Flat pricing. No hidden fees.</LandingPricingContentSubtitle>*/}
      {/*      <LandingPricingContentButtons>*/}
      {/*        <Button shape="circle" onClick={() => navigate('/sign-up')} >Start a Background Check</Button>*/}
      {/*      </LandingPricingContentButtons>*/}
      {/*    </LandingPricingContent>*/}
      {/*  </LandingPricingContentWrapper>*/}
      {/*</LandingPricing>*/}
    </>
  );
}

export default Home;
