import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import {
  InputWrapper,
  NumberInput,
  PasswordInput,
  TextInput,
  DatePickerInput,
  MaskInput,
} from './styled';
import { ValidateStatus as AntdValidateStatus } from 'antd/es/form/FormItem';

export type InputVariants =
  | 'text'
  | 'password'
  | 'number'
  | 'datepicker'
  | 'mask';

export interface InputProps {
  value?: string;
  variant?: InputVariants;
  id?: string;
  className?: string;
  onChange?: (string) => void;
  onKeyPress?: (string) => void;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  passwordVisibility?: boolean;
  errorState?: AntdValidateStatus;
  maskPattern?: any;
  maskGuide?: boolean;
  passwordMask?: boolean;
}

const InputComponent: React.FC<InputProps> = ({
  variant,
  className,
  errorState,
  ...props
}) => {
  const inputVariants: { [key in InputVariants]: ReactNode } = {
    text: TextInput,
    number: NumberInput,
    password: PasswordInput,
    datepicker: DatePickerInput,
    mask: MaskInput,
  };

  const handleChange = useCallback(
    (value) => {
      if (props.onChange) {
        props.onChange(value);
      }
    },
    [props.onChange]
  );

  const handleKeyPress = useCallback(
    (value) => {
      if (props.onKeyPress) {
        props.onKeyPress(value);
      }
    },
    [props.onKeyPress]
  );

  const renderInput = useMemo(() => {
    const inputProps: any = { ...props };

    inputProps.status = errorState;
    inputProps.$errorState = errorState;
    inputProps.onChange = handleChange;
    inputProps.onKeyUp = handleKeyPress;
    switch (variant) {
      case 'password':
        inputProps.icon = props.passwordVisibility;
        break;
      case 'number':
        inputProps.controls = false;
        break;
      case 'datepicker':
        inputProps.popupClassName = 'input-datepicker';
        break;
      case 'mask':
        if (inputProps.passwordMask) {
          inputProps.type = 'password';
          delete inputProps.passwordMask;
        }
        inputProps.mask = inputProps.maskPattern;
        delete inputProps.maskPattern;
        inputProps.guide = inputProps.maskGuide;
        delete inputProps.maskGuide;
        break;
    }

    const Component = inputVariants[variant || 'text'] as React.ElementType;
    return <Component {...inputProps} />;
  }, [
    variant,
    props.disabled,
    props.placeholder,
    props.size,
    props.passwordVisibility,
    errorState,
    handleChange,
  ]);

  return (
    <InputWrapper variant={variant} className={className}>
      {renderInput}
    </InputWrapper>
  );
};

export const Input = memo(InputComponent);
