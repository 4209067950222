import styled, { createGlobalStyle } from 'styled-components';
import { colors, typography } from '../../stories/foundation';
import {
  mediaBreakPointDown,
  mediaBreakPointSingle,
} from '../../stories/breakpoints';
import { AppBody } from '../App/styled';

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  ${mediaBreakPointDown(
    'xs',
    `
      padding: 20px 0;
    `
  )}
`;

export const AuthContent = styled.div`
  display: flex;
  align-items: center;
  min-height: inherit;
`;

export const AuthContentLeft = styled.div`
  flex: 0 0 45%;
  ${mediaBreakPointDown(
    'md',
    `
      flex: 0 0 100%;
    `
  )}
`;

export const AuthContentRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  background: ${colors.light300};
  width: 50%;
  ${mediaBreakPointDown(
    'md',
    `
      width: 0;
    `
  )}
  ${mediaBreakPointSingle(
    'xmd',
    `
      height: 100%;
    `
  )}
`;

export const AuthFormContent = styled.div`
  margin-bottom: 30px;
  h3 {
    color: ${colors.primaryText};
    ${typography.h3}
  }
  h2 {
    ${typography.h2}
  }
`;

export const AuthLinksWrapper = styled.div`
  margin: 35px 0;
  text-align: center;
`;

export const PageFullBodyStyle = createGlobalStyle`
  ${AppBody} {
    padding: 0;
  }
`;
