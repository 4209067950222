import React from 'react';

const MenuIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path d="M3,6c0-0.6,0.4-1,1-1h16c0.6,0,1,0.4,1,1s-0.4,1-1,1H4C3.4,7,3,6.6,3,6z M3,12c0-0.6,0.4-1,1-1h16 c0.6,0,1,0.4,1,1s-0.4,1-1,1H4C3.4,13,3,12.6,3,12z M4,17c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1s-0.4-1-1-1H4z" />
  </svg>
);

export default MenuIcon;
