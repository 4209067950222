import moment from 'moment';
import { CARD_REGEX_PATTERNS } from './constants';

export function stripeCardNumberValidation(cardNumber) {
  for (const cardBrand in CARD_REGEX_PATTERNS) {
    if (
      cardNumber.replace(/[^\d]/g, '').match(CARD_REGEX_PATTERNS[cardBrand])
    ) {
      if (cardNumber) {
        return (
          cardNumber &&
          /^[1-6]{1}[0-9]{14,15}$/i.test(
            cardNumber.replace(/[^\d]/g, '').trim()
          )
        );
      }
    }
  }

  return false;
}

export const stripeCardExpiryFormatValidation = (value) => {
  return /^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim());
};

export const stripeCardExpiryValidation = (value) => {
  const today = new Date();
  const CurrentDate = moment(
    new Date(
      today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
    )
  );
  const visaValue = value.split('/');
  const visaDate = new Date(Number(`20${visaValue[1]}`), visaValue[0], 0);

  return moment(visaDate) > CurrentDate;
};
