import React from 'react';
import { Loader, LoaderContainer } from './styled';

const sizes = {
  large: 16,
  medium: 12,
  small: 8,
};

export interface SpinnerProps {
  size: 'small' | 'medium' | 'large';
  tip?: string;
  className?: string;
  placement?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  size,
  tip,
  className,
  placement,
}: SpinnerProps) => {
  return (
    <LoaderContainer className={className} placement={placement}>
      <Loader size={size}>
        <svg>
          <circle
            cx={sizes[size]}
            cy={sizes[size]}
            r={sizes[size] - 1}
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </Loader>
      {tip && <div>{tip}</div>}
    </LoaderContainer>
  );
};
