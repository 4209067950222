const routes = {
  backgroundCheckRequest: {
    root: '/background-check-request',
    reason: '/background-check-request/reason',
    candidateInformation: '/background-check-request/candidate-information',
    selfDeclaration: '/background-check-request/self-declaration',
    payment: '/background-check-request/payment',
    idVerification: '/background-check-request/identify-verification',
    finish: '/background-check-request/finish',
  },
};

export const ROUTES = routes;
