import { CLIENT_ID, CLIENT_SECRET } from '../../../../env.js';
import React, { useEffect } from 'react';
import useAxiosHttClient from '../../config/http_client';
import { useAuth } from '../../utils/hooks/useAuth';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../utils/constants';

export const SignInHandler = (): JSX.Element => {
  const [signInUser, signOutUser, setUserProfileOnAccount] = useAuth();
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');

  const [{ data: loggedInUserResponse }] = useAxiosHttClient({
    url: '/login/introspect',
    method: 'post',
    data: {
      grant_type: 'password',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      token: authToken,
    },
  });

  useEffect(() => {
    if (!loggedInUserResponse) return;

    if (loggedInUserResponse.active) {
      signInUser(authToken, loggedInUserResponse.user.data);
      if (loggedInUserResponse.user.data.attributes.profile.data) {
        setUserProfileOnAccount(
          loggedInUserResponse.user.data.attributes.profile.data
        );
      }
    } else {
      signOutUser();
    }
  }, [loggedInUserResponse]);

  return <></>;
};
