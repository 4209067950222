import 'antd/dist/antd.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './ontab_verify.css';
import { BrowserRouter } from 'react-router-dom';
import Provider from './provider';
import App from './components/App';
import ScrollToTop from './ScrollToTop';

const container = document.getElementById('root');
const root = createRoot(container);

document.addEventListener('DOMContentLoaded', () =>
  root.render(
    <Provider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  )
);
