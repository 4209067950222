import { CLIENT_ID, CLIENT_SECRET } from '../../../../env.js';
import { Button, Container, Navbar, SideNavDrawer } from 'ov-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import React, { useEffect, useState } from 'react';
import { NavbarMenuItemProps } from '../../stories/atoms/Navbar';
import { AppBody } from '../App/styled';
import { useAccount } from '../../context';
import useAxiosHttClient from '../../config/http_client';
import { notification } from '../../helpers';
import Footer from '../Footer';
import { useAuth } from '../../utils/hooks/useAuth';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../utils/constants';

export const DashboardLayout = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [account] = useAccount();
  const [, signOutUser] = useAuth();
  const { authenticated } = account;
  const location = useLocation();
  const navigate = useNavigate();
  const [sideNavMenuVisible, setSideNavMenuVisible] = useState(false);

  const [, executeSignOut] = useAxiosHttClient(
    {
      url: '/login/revoke',
      method: 'post',
    },
    { manual: true }
  );

  const handleLogOut = async (): Promise<void> => {
    try {
      await executeSignOut({
        data: {
          grant_type: 'password',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          token: authToken,
        },
      });
      signOutUser();
      setSideNavMenuVisible(false);
      notification.success({
        message: 'Logged out successfully',
      });
      navigate('/');
    } catch (error) {
      const errorMessage = error.response.data.message;
      notification.error({
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    setSideNavMenuVisible(false);
  }, [location]);

  const menuItems: NavbarMenuItemProps[] = [
    {
      key: 'logoutButton',
      content: (
        <Button onClick={handleLogOut} variant="secondary" shape="circle">
          Log Out
        </Button>
      ),
      variant: 'button',
      position: 'right',
      hide: !authenticated,
    },
  ];

  return (
    <>
      <AppBody>
        <Navbar
          items={menuItems}
          fillIn={true}
          onSideNavbarButtonClick={() => setSideNavMenuVisible(true)}
        />

        <Container>
          <Outlet />
        </Container>

        <SideNavDrawer
          onLogoClick={() => navigate('/')}
          open={sideNavMenuVisible}
          onClose={() => setSideNavMenuVisible(false)}
          items={menuItems}
        />
      </AppBody>
      <Footer />
    </>
  );
};
