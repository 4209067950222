import _, { capitalize } from 'lodash';
import { notification } from './notification_helpers';

export const getErrorMessages = (errors: string[]): string => {
  let message = '';

  errors.forEach((error) => {
    const lastItem = _.last(errors);
    message += `${error}`;
    if (lastItem === error) {
      if (!lastItem.includes('.')) message += '.';
    } else if (errors[errors.length - 2] === error) {
      message += ' and ';
    } else {
      message += ', ';
    }
  });

  return message;
};

export const displayApiErrors = (errorResponse): void => {
  console.error(errorResponse);
  let message = 'Something went wrong';

  if (errorResponse && errorResponse.response && errorResponse.response.data && errorResponse.response.data.errors && errorResponse.response.data.errors.details) {
    message = getErrorMessages(errorResponse.response.data.errors.details);
  } else if (errorResponse && errorResponse.response && errorResponse.response.data && errorResponse.response.data.error) {
    message = errorResponse.response.data.error;
  }

  notification.error({
    message,
  });
};

export const displayDeviseApiFormMessages = (errorResponse, form): void => {
  if (errorResponse.response.data.errors) {
    Object.entries(errorResponse.response.data.errors).forEach(
      ([key, value]: [string, string[]]) => {
        const errors = value.map(
          (errorMessage) => `${capitalize(key)} ${errorMessage}`
        );

        form.setFields([
          {
            name: key,
            errors,
          },
        ]);
      }
    );

    return;
  }

  notification.error({
    message: 'Something went wrong',
  });
};

export const displayApiSuccess = (): void => {
  notification.success({
    message: 'Coupon successfully applied',
  });
};
