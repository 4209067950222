import React from 'react';

const TwitterIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M22,5.7C22,5.7,22,5.8,22,5.7c0,0.1,0,0.2-0.1,0.3c-0.5,0.6-1,1.2-1.6,1.7c-0.1,0.1-0.2,0.1-0.2,0.3
	c0,3.3-0.8,6.6-3.3,9c-3.1,3-7.7,3.9-11.7,2.7c-0.8-0.2-1.5-0.5-2.3-0.9c-0.2-0.1-0.5-0.3-0.7-0.4C2,18.4,2,18.3,2,18.3
	c0,0,0.1,0,0.3,0c1.1,0.1,2.3-0.1,3.4-0.4c0.6-0.2,1.9-0.6,2.2-1.2c0,0,0,0,0,0c-0.1,0-0.3,0-0.4-0.1c-1.1-0.3-2.6-0.9-3.3-2.7
	c0-0.1,0-0.2,0.1-0.1c0.9,0.1,1.2,0,1.6,0c-0.2,0-0.4-0.1-0.5-0.2C4,13,3,11.6,2.8,10.1c0-0.2,0-0.3,0-0.5c0-0.1,0.1-0.1,0.2-0.1
	C3.6,9.9,4.4,10,4.5,10C4.3,9.9,4.1,9.7,4,9.6C2.8,8.4,2.3,6.1,3.3,4.6c0.1-0.1,0.1-0.1,0.2,0c2.2,2.5,4.9,3.7,8.2,4.2
	c0.1,0,0.1,0,0.1-0.1c-0.1-0.6-0.1-1.1,0-1.7c0.1-0.6,0.4-1.2,0.8-1.6c0.4-0.5,0.8-0.8,1.4-1.1c0.5-0.3,1.1-0.4,1.7-0.5
	c0.6,0,1.2,0.1,1.8,0.3c0.4,0.2,0.8,0.4,1.2,0.7c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.2,0c0.8-0.2,1.6-0.5,2.4-0.9
	c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c-0.1,0.4-0.4,0.9-0.7,1.2c-0.2,0.2-0.7,0.8-1,0.9C20.6,6.2,21.3,6,22,5.7C22,5.7,22,5.7,22,5.7z"
    />
  </svg>
);

export default TwitterIcon;
