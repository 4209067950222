import React from 'react';

const FacebookIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M16.6,13.4l0.6-3.6h-3.5V7.4c0-1,0.5-2,2-2h1.6V2.4c0,0-1.4-0.2-2.8-0.2c-2.9,0-4.7,1.7-4.7,4.9v2.8H6.6v3.6
	h3.2v8.7h3.9v-8.7H16.6z"
    />
  </svg>
);

export default FacebookIcon;
