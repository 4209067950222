import React from 'react';

const CheckCircleIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      d="M12,22c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C6.5,2,2,6.5,2,12C2,17.5,6.5,22,12,22z M15.5,8.5
	c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-5.7,5.7l0,0c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.4,0.1-0.6,0c-0.2,0-0.4-0.1-0.5-0.3
	l0,0l-2.8-2.8c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.1,2.1L15.5,8.5z"
    />
  </svg>
);

export default CheckCircleIcon;
