import React from 'react';

const LinkedinIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M2.3,8.7h4.3v12.9H2.3V8.7z M4.4,2.4C3,2.4,2,3.4,2,4.7c0,1.2,0.9,2.2,2.4,2.2h0c1.5,0,2.4-1,2.4-2.2
	C6.8,3.4,5.9,2.4,4.4,2.4z M17.1,8.4c-2.3,0-3.3,1.3-3.9,2.1V8.7H8.9c0.1,1.2,0,12.9,0,12.9h4.3v-7.2c0-0.4,0-0.8,0.1-1
	c0.3-0.8,1-1.6,2.2-1.6c1.6,0,2.2,1.2,2.2,2.9v6.9H22v-7.4C22,10.2,19.9,8.4,17.1,8.4L17.1,8.4z"
    />
  </svg>
);

export default LinkedinIcon;
