import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAccount } from '../../context';
import { flashNotification } from '../../helpers';
import { AppWrapper } from './styled';
import { SignInHandler } from '../authentication/SignInHandler';
import { DashboardLayout, HomeLayout } from '../layouts';
import { BackgroundCheckRequest } from '../dashboard/BackgroundCheckRequest';
import Home from '../landing/Home';
import Login from '../authentication/Login';
import SignUp from '../authentication/SignUp';
import ForgetPassword from '../authentication/ForgetPassword';
import { PageNotFound } from '../PageNotFound';
import { ROUTES } from '../../routes';
import Terms from '../landing/Terms';
import { AUTH_TOKEN_KEY } from '../../utils/constants';

const App = (): JSX.Element => {
  const [account] = useAccount();
  const { authenticated } = account;
  const flashMessage = document.querySelector('[name=flash-message]');

  useEffect(() => {
    if (flashMessage) {
      const flashMessageType = flashMessage.getAttribute('type') || 'success';
      const flashMessageContent = flashMessage.getAttribute('content');
      flashNotification(flashMessageType, flashMessageContent, {
        duration: 3,
      });

      flashMessage.remove();
    }
  }, [flashMessage]);

  const authenticationTokenExists = !!localStorage.getItem(AUTH_TOKEN_KEY);
  const shouldSignInUser = authenticationTokenExists !== authenticated;

  if (shouldSignInUser) {
    return <SignInHandler />;
  }

  return (
    <>
      <AppWrapper>
        <Routes>
          {authenticated ? (
            <Route path="/" element={<DashboardLayout />}>
              <Route
                path="/"
                element={<Navigate to={ROUTES.backgroundCheckRequest.root} />}
              />
              <Route
                path={`${ROUTES.backgroundCheckRequest.root}/*`}
                element={<BackgroundCheckRequest />}
              />
              <Route path="terms" element={<Terms />} />
            </Route>
          ) : (
            <Route path="/" element={<HomeLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="terms" element={<Terms />} />
              <Route path="login" element={<Login />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="password/*" element={<ForgetPassword />} />
            </Route>
          )}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AppWrapper>
    </>
  );
};

export default App;
