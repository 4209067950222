import React, { memo } from 'react';
import { Form as AntdForm } from 'antd';
import { Select, SelectProps } from '../../atoms/Select';

const FormItemSelectComponent: React.FC<SelectProps> = (props) => {
  const { status } = AntdForm.Item.useStatus() as any;
  return <Select {...props} size={'large'} $errorStatus={status} />;
};

export const FormItemSelect = memo(FormItemSelectComponent);
