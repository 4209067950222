import React from 'react';
import {
  FooterWrapper,
  FooterLogoWrapper,
  FooterLogo,
  FooterLinksWrapper,
  FooterLinks,
  FooterLinkHeader,
  FooterLink,
  FooterCopyRight,
  FooterMarketingMailLink,
  FooterAnchor,
} from './styled';
import { Container } from 'ov-components';
import { Col, Row } from 'antd';
import { Icon } from '../../stories/atoms/Icon';

const Footer = () => {
  const copyrightYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <Container>
        <Row justify="space-between">
          <Col xs={24} md={10} xl={8}>
            <FooterLogoWrapper>
              <FooterLogo />
            </FooterLogoWrapper>
          </Col>
          <Col xs={24} md={14} xl={16}>
            <FooterLinksWrapper>
              <Row justify="end" gutter={{ xl: 24 }}>
                <Col xs={24} xl={8}>
                  <FooterLinks>
                    <FooterLinkHeader>ONTAB Verify</FooterLinkHeader>
                    <FooterLink to="/">Criminal Background Check</FooterLink>
                  </FooterLinks>
                </Col>
                <Col xs={24} xl={6}>
                  <FooterLinks>
                    <FooterLinkHeader>Resources</FooterLinkHeader>
                    {/*<FooterLink to="/faq">FAQs</FooterLink>*/}
                    <FooterLink to="/terms">Terms and Conditions</FooterLink>
                    <FooterAnchor
                      href="https://ontab.com/privacy/"
                      target="_blank"
                    >
                      Privacy
                    </FooterAnchor>
                  </FooterLinks>
                </Col>
              </Row>
            </FooterLinksWrapper>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={24} md={10} lg={10} xl={6}>
            <FooterCopyRight>
              &copy; {copyrightYear} ONTAB INC. All rights reserved.
            </FooterCopyRight>
          </Col>
          <Col xs={24} md={14} lg={12} xl={9}>
            <Row justify="space-between">
              <Col xxl={11}>
                <FooterMarketingMailLink>
                  <Icon name="mail" />{' '}
                  <a href="mailto:verify@ontab.com">verify@ontab.com</a>
                </FooterMarketingMailLink>
              </Col>
              <Col xxl={3}>
                <FooterAnchor
                  href="https://www.linkedin.com/company/ontab/"
                  target="_blank"
                >
                  <Icon name="linkedin" cursor={'pointer'} />
                </FooterAnchor>
              </Col>
              <Col xxl={3}>
                <FooterAnchor
                  href="https://instagram.com/ontabinc"
                  target="_blank"
                >
                  <Icon name="instagram" cursor={'pointer'} />
                </FooterAnchor>
              </Col>
              <Col xxl={3}>
                <FooterAnchor
                  href="https://instagram.com/ontabinc"
                  target="_blank"
                >
                  <Icon name="twitter" cursor={'pointer'} />
                </FooterAnchor>
              </Col>
              <Col xxl={3}>
                <FooterAnchor
                  href="https://www.facebook.com/ontabinc"
                  target="_blank"
                >
                  <Icon name="facebook" cursor={'pointer'} />
                </FooterAnchor>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
