import styled from 'styled-components';
import { colors, typography } from '../../../stories/foundation';
import {
  mediaBreakPointDown,
  mediaBreakPointUp,
} from '../../../stories/breakpoints';
import { Button, Spinner } from 'ov-components';
import { Link } from 'react-router-dom';

type StepSectionProps = {
  divider?: boolean;
};

type BackgroundCheckRequestActionsProps = {
  firstStep?: boolean;
  lastStep?: boolean;
};

export const BackgroundCheckRequestLoader = styled(Spinner)`
  display: flex;
`;

export const BackgroundCheckRequestWrapper = styled.div``;

export const BackgroundCheckRequestHeader = styled.div`
  text-align: center;
`;

export const BackgroundCheckRequestTitle = styled.h1`
  ${typography.h1}
  ${mediaBreakPointDown(
    'sm',
    `
      ${typography.h2}
    `
  )}
`;

export const BackgroundCheckRequestSubTitle = styled.p`
  ${typography.p1}
`;

export const BackgroundCheckRequestContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  ${mediaBreakPointUp(
    'md',
    `
    width: 75%;
  `
  )}
  ${mediaBreakPointUp(
    'lg',
    `
    width: 50%;
  `
  )}
`;

export const BackgroundCheckRequestConfirmButton = styled(Button)`
  ${mediaBreakPointDown(
    'sm',
    `
      width: 100%;
    `
  )}
`;

export const BackgroundCheckRequestPreviousLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.dark500};
  font-size: 16px;
`;

export const BackgroundCheckRequestActions = styled.div<BackgroundCheckRequestActionsProps>`
  margin: 45px 0;
  padding-top: 45px;
  border-top: 3px solid ${colors.light500};
  display: flex;
  justify-content: ${(props) =>
    props.firstStep ? 'flex-end' : 'space-between'};
  align-items: center;
  div {
    margin: 0;
  }
  ${({ lastStep }) =>
    lastStep
      ? mediaBreakPointDown('sm', `flex-direction: column;`)
      : mediaBreakPointDown('sm', `flex-direction: column-reverse;`)}
  ${mediaBreakPointDown('sm', `gap: 28px;`)}
`;

export const StepTitle = styled.h3`
  ${typography.h3}
  margin-bottom: 25px;
  text-align: center;
`;

export const StepSection = styled.div<StepSectionProps>`
  margin-bottom: 45px;
  ${(props) =>
    props.divider &&
    `
    padding-top: 45px;
    border-top: 3px solid ${colors.light500};
  `}
`;

export const StepSubTitle = styled.p`
  ${typography.p1}
  margin-bottom: 30px;
  text-align: center;
`;
