import { sizes } from './sizes';
const minWidthDevices = {
  minMobileS: `(min-width: ${sizes.mobileS})`,
  minMobileM: `(min-width: ${sizes.mobileM})`,
  minMobileL: `(min-width: ${sizes.mobileL})`,
  minTablet: `(min-width: ${sizes.tablet})`,
  minLaptop: `(min-width: ${sizes.laptop})`,
  minLaptopL: `(min-width: ${sizes.laptopL})`,
  minDesktop: `(min-width: ${sizes.desktop})`,
};

const maxWidthDevices = {
  maxMobileS: `(max-width: ${sizes.mobileS})`,
  maxMobileM: `(max-width: ${sizes.mobileM})`,
  maxMobileL: `(max-width: ${sizes.mobileL})`,
  maxTablet: `(max-width: ${sizes.tablet})`,
  maxLaptop: `(max-width: ${sizes.laptop})`,
  maxLaptopL: `(max-width: ${sizes.laptopL})`,
  maxDesktop: `(max-width: ${sizes.desktop})`,
};

const devices = {
  ...minWidthDevices,
  ...maxWidthDevices,
};

export default devices;
