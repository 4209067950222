import React from 'react';

const PlusIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      d="M12,5c-0.6,0-1,0.4-1,1v5H6c-0.6,0-1,0.4-1,1s0.4,1,1,1h5v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-5h5c0.6,0,1-0.4,1-1
	s-0.4-1-1-1h-5V6C13,5.4,12.6,5,12,5z"
    />
  </svg>
);

export default PlusIcon;
