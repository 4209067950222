import React, { memo } from 'react';
import { colors } from '../../foundation';

export interface TextProps {
  children?: JSX.Element | string;
  color?: string;
  center?: boolean;
  size?: number;
  weight?: number;
  style?: any;
}

const TextComponent: React.FC<TextProps> = ({
  children,
  color,
  center = false,
  size,
  weight,
  style = {},
}) => {
  const textColor = color ? colors[color] : undefined;
  const styles: any = style;
  styles.textAlign = center ? 'center' : 'left';
  styles.color = textColor;
  if (size) {
    styles.fontSize = size;
  }
  if (weight) {
    styles.fontWeight = weight;
  }

  return <div style={styles}>{children}</div>;
};

export const Text = memo(TextComponent);
