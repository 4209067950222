import { DEFAULT_ACCOUNT, DEFAULT_USER, useAccount } from '../../context';
import { UserType, ProfileType } from '../../api_types';
import { useLocalStorage } from './useLocalStorage';
import { AUTH_TOKEN_KEY } from '../constants';

export function useAuth() {
  const [account, setAccount, , setUserId, , setUserProfileId] = useAccount();
  const [, setAuthToken, removeAuthToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const signInUser = (token: string, user: UserType): void => {
    setUserId(user.id);
    setAuthToken(token);

    const authenticatedAccount = { ...DEFAULT_ACCOUNT, authenticated: true };
    authenticatedAccount.user.email = user.attributes.email;
    authenticatedAccount.user.roles = user.attributes.roles;

    setAccount(authenticatedAccount);
  };

  const setUserProfileOnAccount = (profile: ProfileType): void => {
    const userProfileOnAccount = { ...account, authenticated: true };
    userProfileOnAccount.user.name = profile.attributes.fullName;
    userProfileOnAccount.user.firstName = profile.attributes.firstName;
    userProfileOnAccount.user.lastName = profile.attributes.lastName;
    userProfileOnAccount.user.sex = profile.attributes.sex;
    userProfileOnAccount.user.dateOfBirth = profile.attributes.dateOfBirth;
    userProfileOnAccount.user.birthCountry = profile.attributes.birthCountry;
    userProfileOnAccount.user.phoneNumber = profile.attributes.phoneNumber;
    userProfileOnAccount.user.address =
      profile.attributes.homeAddress &&
      profile.attributes.homeAddress.data &&
      profile.attributes.homeAddress.data.attributes;

    setUserProfileId(profile.id);
    setAccount(userProfileOnAccount);
  };

  const signOutUser = (): void => {
    removeAuthToken();
    setAccount({
      authenticated: false,
      user: DEFAULT_USER,
    });
  };

  return [signInUser, signOutUser, setUserProfileOnAccount] as const;
}
