import { notification as antDNotification } from 'antd';

antDNotification.config({
  placement: 'topRight',
  duration: 3.5,
});

export const notification = antDNotification;

export const flashNotification = (
  type: string,
  message: string | null,
  options: any = {}
) => {
  let notificationType = '';
  switch (type) {
    case 'notice':
      notificationType = 'success';
      break;
    case 'alert':
      notificationType = 'error';
      break;
    case 'error':
      notificationType = 'error';
      break;
    default:
      notificationType = 'success';
  }
  notification[notificationType]({
    message: message,
    ...options,
  });
};
