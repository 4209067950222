import React from 'react';

import {
  AuthWrapper as ForgotPasswordWrapper,
  AuthContent as ForgotPasswordContent,
  AuthContentLeft as ForgotPasswordContentLeft,
  AuthContentRight as ForgotPasswordContentRight,
  PageFullBodyStyle,
} from '../styled';

import { Container } from 'ov-components';
import { Route, Routes } from 'react-router-dom';
import { ResetPassword } from './ResetPassword';
import { EditPassword } from './EditPassword';

const ForgetPassword = () => {
  return (
    <>
      <PageFullBodyStyle />
      <ForgotPasswordWrapper>
        <ForgotPasswordContent>
          <ForgotPasswordContentLeft>
            <Container>
              <Routes>
                <Route path="/reset" element={<ResetPassword />} />
                <Route path="edit" element={<EditPassword />} />
              </Routes>
            </Container>
          </ForgotPasswordContentLeft>
          <ForgotPasswordContentRight />
        </ForgotPasswordContent>
      </ForgotPasswordWrapper>
    </>
  );
};

export default ForgetPassword;
