import styled, { css } from 'styled-components';
import { colors, typography } from '../../stories/foundation';
import { OntabLogo } from '../App/styled';
import { cssOnMobileDevices } from '../../stories/breakpoints';
import { Link } from 'react-router-dom';

export const FooterWrapper = styled.div`
  position: relative;
  background-color: ${colors.blue500};
  padding: 60px 1em 50px;
  color: ${colors.white};
  ${cssOnMobileDevices(css`
    text-align: center;
  `)}
`;

export const FooterLogoWrapper = styled.div`
  margin-bottom: 70px;
  ${cssOnMobileDevices(css`
    margin-bottom: 30px;
  `)}
`;

export const FooterLogo = styled(OntabLogo)`
  width: 250px;
  margin-bottom: 20px;
`;

export const FooterLinksWrapper = styled.div`
  margin-bottom: 70px;
  ${cssOnMobileDevices(css`
    margin-bottom: 20px;
  `)}
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterCopyRight = styled.div`
  ${cssOnMobileDevices(css`
    margin-bottom: 20px;
  `)}
`;

export const FooterMarketingMailLink = styled.div`
  display: inline-flex;
  a {
    color: ${colors.white};
    margin-left: 5px;
  }
`;

export const FooterLinkHeader = styled.div`
  ${typography.p1Bold}
  margin-bottom: 15px;
`;

const footerLinkStyles = css`
  ${typography.p2}
  margin-bottom: 15px;
  color: ${colors.white};
`;

export const FooterLink = styled(Link)`
  ${footerLinkStyles};
`;

export const FooterAnchor = styled.a`
  ${footerLinkStyles};
`;
