import React, { memo } from 'react';
import { SelectWrapper } from './styled';

export interface SelectProps {
  value?: string | null;
  defaultValue?: string | null;
  items?: {
    label: string | number;
    value: string | number;
    disabled?: boolean;
  }[];
  groupOptions?: {
    label: string;
    options: { label: string | number; value: string | number };
  }[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onSelect?: (string) => void;
  size?: 'small' | 'medium' | 'large';
  placeholder?: string;
  search?: boolean;
  $errorStatus?: 'error' | 'warning';
}

const SelectComponent: React.FC<SelectProps> = ({
  value,
  defaultValue,
  items,
  groupOptions,
  onChange,
  onSelect,
  size,
  placeholder,
  search,
  $errorStatus,
}) => {
  return (
    <SelectWrapper
      defaultValue={defaultValue}
      value={value}
      size={size}
      placeholder={placeholder}
      onChange={onChange}
      onSelect={onSelect}
      options={groupOptions || items}
      showSearch={search}
      showArrow
      popupClassName="select-popup"
      $errorState={$errorStatus}
    />
  );
};

export const Select = memo(SelectComponent);
