import { makeUseAxios } from 'axios-hooks';
import LRU from 'lru-cache';
import Axios from 'axios';

const csrfTokenElement = document.querySelector("[name=csrf-token]");
const csRFToken = csrfTokenElement ? csrfTokenElement.getAttribute("content") : undefined;

const axios = Axios.create({
  baseURL: '/api/',
  timeout: 10000,
  headers: {
    'X-CSRF-TOKEN': csRFToken,
  },
});
const cache = new LRU({ max: 10 });

const useAxiosHttClient = makeUseAxios({
  axios: axios,
  cache: cache,
});

export default useAxiosHttClient;
