import React, { createContext, useContext } from 'react';

export type AccountUserAddressType = {
  line1: string;
  line2?: string;
  city: string;
  state?: string;
  country: string;
  zipcode?: string;
};

export type AccountUserType = {
  id: string;
  email: string;
  name: string | undefined;
  firstName: string;
  lastName: string;
  sex: string;
  dateOfBirth: string;
  birthCountry: string;
  phoneNumber: string;
  roles: string[];
  address?: AccountUserAddressType;
};

export type AccountType = {
  authenticated?: boolean;
  user: AccountUserType;
};

type AccountContextType = [
  AccountType,
  React.Dispatch<React.SetStateAction<AccountType>>,
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string,
  React.Dispatch<React.SetStateAction<string>>
];

export const DEFAULT_USER: AccountUserType = {
  id: '',
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  sex: '',
  dateOfBirth: '',
  birthCountry: '',
  phoneNumber: '',
  roles: [],
};

export const DEFAULT_ACCOUNT = {
  authenticated: false,
  user: DEFAULT_USER,
};

export const AccountContext = createContext({} as AccountContextType);

export function useAccount() {
  return useContext(AccountContext);
}
