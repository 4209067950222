import styled from 'styled-components';
import { Card, Radio } from 'antd';
import { colors, typography } from '../../../../stories/foundation';
import { Icon } from '../../../../stories/atoms/Icon';
import { mediaBreakPointDown } from '../../../../stories/breakpoints';

export const CriminalRecordButtonCardIcon = styled(Icon)`
  margin-right: 12px;
`;

export const CriminalRecordButtonCard = styled(Card)`
  border: none;
  border-radius: 6px !important;
  background-color: ${colors.light300};
  .ant-card-body {
    width: 100%;
    display: flex;
    padding: 15px 12px;
    align-items: center;
  }
`;

export const CriminalRecordButtonTitle = styled.p`
  ${typography.p1Bold}
  margin-bottom: 5px;
`;

export const CriminalRecordButtonSubTitle = styled.p`
  ${typography.p1}
  margin: 0;
`;

export const CriminalRecordRadio = styled(Radio)``;

export const CriminalRecordRadioButtons = styled(CriminalRecordRadio.Group)`
  display: flex;
  gap: 30px;
  flex-direction: row;
  ${mediaBreakPointDown(
    'sm',
    `
      gap: 25px;
      flex-direction: column;
    `
  )}
  > label {
    flex: 50%;
    height: 100%;
    padding: 0;
    border-radius: 6px !important;
    border: none !important;
    &::before {
      content: none !important;
    }
    &.ant-radio-button-wrapper-checked {
      border: 1px solid ${colors.primaryText} !important;
      box-shadow: 0 0 8px 2px ${colors.light500};
      ${CriminalRecordButtonCard} {
        background-color: ${colors.white};
      }
      ${CriminalRecordButtonCardIcon} {
        color: ${colors.primaryText};
      }
    }
  }
`;

export const PreviousRecordFormInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
`;

export const PreviousRecordFormActions = styled.div`
  text-align: center;
  button {
    padding: 10px 35px;
  }
`;
