import React, { useEffect, useRef, useState } from 'react';
import {
  BackgroundCheckRequestActions,
  BackgroundCheckRequestConfirmButton,
  StepSection,
  StepSubTitle,
  StepTitle,
} from '../styled';
import { Col, Row, Divider } from 'antd';
import { Button, Text, Spinner, Input } from 'ov-components';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import { notification } from '../../../../helpers';

import {
  AcceptTermsCheckbox,
  AddNewCard,
  CreditCardSelectionCard,
  CreditCardSelectionCardHeader,
  CreditCardSelectionCardFooter,
  CreditCardBrand,
  CreditCardDetails,
  CreditCardSelectionRadio,
  CreditCardSelectionRadioButtons,
  ProductPricingDetails,
  AddNewCreditCardModal,
  DeleteCreditCardModal,
  AddNewCreditCardModalTitle,
  DeleteCreditCardModalTitle,
  DeleteCreditCardModalContent,
  EmptyCard,
  ConsentModal,
  ConsentModalTitle,
  ConsentModalContent,
  CreditCardRemoveIcon,
  CreditCardEditIcon,
  EditCreditCardModal,
  EditCreditCardModalTitle,
} from './styled';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { uniq } from 'lodash';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';
import {
  BackgroundCheckPaymentStatusEnum,
  BackgroundCheckRequestStatusEnum,
} from '../types';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '../../../../stories/atoms/Icon';
import { CreditCardForm } from './CreditCardForm';
import { CreditCard } from '../../../../api_types';
import { CardIcon } from './CreditCardForm/helpers';
import {
  displayApiErrors,
  displayApiSuccess,
} from '../../../../helpers/error_messages_helpers';
import { CVC } from './CreditCardForm/constants';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { getStepsBeforePayment } from '../../../../helpers/background_check_steps_helpers';
import { EditCreditCardForm } from './CreditCardForm/Edit';
import { CreditCardFormRef } from './CreditCardForm/types';

export const Payment: React.FC = () => {
  const navigate = useNavigate();
  const creditCardFormRef = useRef<CreditCardFormRef>(null);
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [
    steps,
    setSteps,
    ,
    setActiveStep,
    backgroundCheckRequest,
    setBackgroundCheckRequest,
    ,
    setDisabledSteps,
  ] = useBackgroundCheckRequestSteps();
  const paymentSucceeded =
    backgroundCheckRequest &&
    backgroundCheckRequest.paymentStatus === BackgroundCheckPaymentStatusEnum.SUCCEEDED;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showAddNewCardModal, setShowAddNewCardModal] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [
    showEditCreditCardConfirmationModal,
    setShowEditCreditCardConfirmationModal,
  ] = useState(false);
  const [
    showDeleteCreditCardConfirmationModal,
    setShowDeleteCreditCardConfirmationModal,
  ] = useState(false);
  const [selectedCreditCardId, setSelectedCreditCardId] = useState<
    string | undefined
  >();
  const [editCreditCard, setEditCreditCard] = useState<CreditCard | null>();
  const [newCreditCardId, setNewCreditCardId] = useState<string | null>();
  const [defaultCreditCardSelection, setDefaultCreditCardSelection] =
    useState(true);
  const [existingCardCVV, setExistingCardCVV] = useState<string | undefined>();
  const [coupon, setCoupon] = useState<string | undefined>();

  const [existingCardCVVErrorState, setExistingCardCVVErrorState] =
    useState<ValidateStatus>('');

  const [
    { loading: loadingCreditCards, data: creditCardsListResponse },
    refetch,
  ] = useAxiosHttClient({
    url: '/credit_cards',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  const refetchCreditCards = (withDefaultSelection = true) => {
    setDefaultCreditCardSelection(withDefaultSelection);
    refetch();
  };

  const creditCardsList: CreditCard[] = creditCardsListResponse && creditCardsListResponse.data;

  useEffect(() => {
    if (!defaultCreditCardSelection || !creditCardsList) {
      return;
    }

    const defaultCreditCard = creditCardsList.find(
      (card) => card.attributes.default
    );

    if (defaultCreditCard) {
      setSelectedCreditCardId(defaultCreditCard.id);
    }
  }, [creditCardsList]);

  const [{ loading: processingPayment }, backgroundCheckPaymentApi] =
    useAxiosHttClient(
      {
        url: '/background_check/payment',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      },
      { manual: true }
    );

  const [{ loading: deletingCreditCard }, deleteCreditCardApi] =
    useAxiosHttClient(
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      },
      { manual: true }
    );

  const [
    { loading: updatingBackgroundCheckRecord },
    updateBackgroundCheckRequestApi,
  ] = useAxiosHttClient(
    {
      url: '/background_check',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const [{ loading: submittingApplication }, submitBackgroundCheckApplication] =
    useAxiosHttClient(
      {
        url: '/background_check/verify',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      },
      { manual: true }
    );

  const closeCreditCardFormModal = () => {
    creditCardFormRef && creditCardFormRef.current && creditCardFormRef.current.clearForm && creditCardFormRef.current.clearForm();
    setShowAddNewCardModal(false);
  };

  const closeEditCreditCardFormModal = () => {
    setEditCreditCard(null);
    creditCardFormRef && creditCardFormRef.current && creditCardFormRef.current.clearForm && creditCardFormRef.current.clearForm();
    setShowEditCreditCardConfirmationModal(false);
  };

  const closeConsentModal = () => {
    setShowConsentModal(false);
  };

  const openDeleteCreditCardConfirmationModal = () => {
    setShowDeleteCreditCardConfirmationModal(true);
  };

  const openEditCreditCardConfirmationModal = () => {
    const creditCard = creditCardsList.find(
      (card) => card.id === selectedCreditCardId
    );
    setEditCreditCard(creditCard);
    setShowEditCreditCardConfirmationModal(true);
  };

  const closeDeleteCreditCardConfirmationModal = () => {
    setShowDeleteCreditCardConfirmationModal(false);
  };

  const showExistingCreditCardCVVError = (errorMessage) => {
    notification.error({
      message: errorMessage,
    });
    closeConsentModal();
    setExistingCardCVVErrorState('error');
  };

  const handleOnConfirm = async () => {
    if (
      backgroundCheckRequest.paymentStatus ===
      BackgroundCheckPaymentStatusEnum.PENDING
    ) {
      if (newCreditCardId !== selectedCreditCardId && !coupon) {
        if (!existingCardCVV) {
          showExistingCreditCardCVVError('Please enter your CVV');
          return;
        }

        const validExistingCreditCardCVVV =
          existingCardCVV && existingCardCVV.length === CVC.length;
        if (!validExistingCreditCardCVVV) {
          showExistingCreditCardCVVError(
            'Your CVV is invalid. Must be 3 digits.'
          );
          return;
        }
      }

      setExistingCardCVVErrorState('');

      try {
        await backgroundCheckPaymentApi({
          data: {
            credit_card_id: selectedCreditCardId,
            card_cvv: existingCardCVV,
            coupon_code: coupon,
          },
        });

        setBackgroundCheckRequest({
          ...backgroundCheckRequest,
          paymentStatus: BackgroundCheckPaymentStatusEnum.SUCCEEDED,
        });
        if(document.querySelector('.ant-radio-button-checked') === null) {
          displayApiSuccess();
        }
      } catch (exceptionError) {
        closeConsentModal();
        setNewCreditCardId(null);
        setExistingCardCVV('');
        displayApiErrors(exceptionError);
        return;
      }
    }

    if (
      backgroundCheckRequest.status === BackgroundCheckRequestStatusEnum.PENDING
    ) {
      try {
        await submitBackgroundCheckApplication();
      } catch (exceptionError) {
        closeConsentModal();
        displayApiErrors(exceptionError);
        return;
      }
    }

    try {
      const completedSteps = uniq([
        ...steps,
        BackgroundCheckRequestStepsEnum.IdVerification,
      ]);
      const currentStep = BackgroundCheckRequestStepsEnum.IdVerification;

      await updateBackgroundCheckRequestApi({
        data: {
          completed_steps: completedSteps,
          current_step: currentStep,
          consent: true,
        },
      });

      setBackgroundCheckRequest({
        ...backgroundCheckRequest,
        consent: true,
        status:
          BackgroundCheckRequestStatusEnum.IDENTITY_VERIFICATION_IN_PROGRESS,
      });
      setSteps(completedSteps);
      setActiveStep(currentStep);
      setDisabledSteps(getStepsBeforePayment());
      navigate(tabRoutes[currentStep]);
    } catch (exceptionError) {
      displayApiErrors(exceptionError);
      return;
    }
  };

  const deleteCreditCard = async (creditCardId) => {
    if (!creditCardId) {
      return;
    }

    try {
      await deleteCreditCardApi({
        url: `/credit_cards/${creditCardId}`,
      });
      closeDeleteCreditCardConfirmationModal();
      await refetchCreditCards();
      notification.success({
        message: 'Credit Card was successfully removed!',
      });
    } catch (exceptionError) {
      displayApiErrors(exceptionError);
    }
  };

  const renderCreditCardItem = (creditCard: CreditCard) => {
    const selectedItem = selectedCreditCardId === creditCard.id;

    return (
      <CreditCardSelectionRadio.Button
        value={creditCard.id}
        key={`credit-card-${creditCard.id}`}
      >
        <CreditCardSelectionCard>
          <CreditCardSelectionCardHeader>
            {creditCard.attributes.brandName && (
              <CreditCardBrand>
                <CardIcon cardType={creditCard.attributes.brandName} />
              </CreditCardBrand>
            )}
            <CreditCardDetails>
              <span>{creditCard.attributes.cardholder}</span>
              <span>**** **** **** {creditCard.attributes.last4}</span>
              <span>
                {creditCard.attributes.expiryMonth} /{' '}
                {creditCard.attributes.expiryYear}
              </span>
            </CreditCardDetails>
            {selectedItem && (
              <>
                <CreditCardEditIcon
                  display={'flex'}
                  name={'edit'}
                  cursor={'pointer'}
                  variant={'small'}
                  onClick={openEditCreditCardConfirmationModal}
                />
                <CreditCardRemoveIcon
                  display={'flex'}
                  name={'close'}
                  cursor={'pointer'}
                  onClick={openDeleteCreditCardConfirmationModal}
                />
              </>
            )}
          </CreditCardSelectionCardHeader>
          {selectedItem &&
            newCreditCardId !== selectedCreditCardId &&
            !paymentSucceeded && (
              <CreditCardSelectionCardFooter>
                <Text>CVC:</Text>
                <Input
                  passwordMask
                  value={existingCardCVV}
                  onChange={(e) => setExistingCardCVV(e.target.value)}
                  variant={'mask'}
                  maskPattern={CVC}
                  maskGuide={false}
                  errorState={existingCardCVVErrorState}
                />
              </CreditCardSelectionCardFooter>
            )}
        </CreditCardSelectionCard>
      </CreditCardSelectionRadio.Button>
    );
  };

  const termsCheckboxDisabled =
    coupon === undefined &&
    (creditCardsList && creditCardsList.length === 0) || (creditCardFormRef && creditCardFormRef.current && creditCardFormRef.current.loading);
  const confirmButtonDisabled = !termsAccepted;

  const consentButtonsDisabled =
    coupon === undefined &&
    (submittingApplication ||
      processingPayment ||
      updatingBackgroundCheckRecord);

  const deleteCreditCardButtonsDisabled = deletingCreditCard;

  return (
    <>
      <StepSection>
        <StepTitle style={{ 'marginBottom': '8px' }}>Payment Details</StepTitle>
        <StepSubTitle>Complete your payment to continue</StepSubTitle>

        <Row gutter={[24, 24]}>
          <Col md={12} xs={24}>
            <ProductPricingDetails>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>Background Check</td>
                    <td align={'right'}>$49.90</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td align={'right'}>$6.49</td>
                  </tr>
                  <tr>
                    <td>Coupon</td>
                    <td align={'right'}>
                      <Input
                        variant={'text'}
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider />
              <Text>Your Total</Text>
              <Text size={20} weight={500}>
                $56.39
              </Text>
            </ProductPricingDetails>
          </Col>

          <Col md={12} xs={24}>
            {creditCardsList ? (
              creditCardsList.length === 0 ? (
                <EmptyCard $info>{"You don't have any credit card"}</EmptyCard>
              ) : (
                <CreditCardSelectionRadioButtons
                  value={selectedCreditCardId}
                  onChange={(e) => setSelectedCreditCardId(e.target.value)}
                >
                  {creditCardsList && creditCardsList.map(function(creditCard) {
                    return renderCreditCardItem(creditCard);
                  })}
                </CreditCardSelectionRadioButtons>
              )
            ) : loadingCreditCards ? (
              <Text center>
                <Spinner size={'medium'} />
              </Text>
            ) : (
              <EmptyCard $error>
                There was a problem in loading the credit cards data.
              </EmptyCard>
            )}
          </Col>
        </Row>

        <AddNewCard>
          <Text center size={16}>
            {"Don't see your payment method? "}
          </Text>
          <Button
            iconVariant={'large'}
            variant="secondary"
            shape="circle"
            icon={'plus'}
            onClick={() => setShowAddNewCardModal(true)}
          >
            Add New Card
          </Button>
        </AddNewCard>
      </StepSection>
      <BackgroundCheckRequestActions lastStep>
        <AcceptTermsCheckbox
          disabled={termsCheckboxDisabled}
          onChange={(e) => setTermsAccepted(e.target.checked)}
        >
          You must accept{' '}
          <Link to={'/terms'} target="_blank">
            Terms and Conditions
          </Link>{' '}
          to proceed.
        </AcceptTermsCheckbox>
        <BackgroundCheckRequestConfirmButton
          variant="primary"
          shape="circle"
          disabled={confirmButtonDisabled}
          onClick={() => setShowConsentModal(true)}
        >
          Confirm and Continue
        </BackgroundCheckRequestConfirmButton>
      </BackgroundCheckRequestActions>

      <AddNewCreditCardModal
        maskClosable={false}
        width={335}
        closeIcon={
          <Icon name={'close'} fillColor={'dark500'} cursor={'pointer'} />
        }
        title={
          <AddNewCreditCardModalTitle>
            Add a new Credit Card
          </AddNewCreditCardModalTitle>
        }
        open={showAddNewCardModal}
        footer={null}
        onCancel={() => {
          if (creditCardFormRef && creditCardFormRef.current && creditCardFormRef.current.loading) {
            return false;
          }

          closeCreditCardFormModal();
        }}
      >
        <CreditCardForm
          ref={creditCardFormRef}
          onCreditCardSave={setNewCreditCardId}
          refetchCreditCards={refetchCreditCards}
          closeFormModal={closeCreditCardFormModal}
        />
      </AddNewCreditCardModal>

      {editCreditCard && (
        <EditCreditCardModal
          maskClosable={false}
          width={335}
          closeIcon={
            <Icon name={'close'} fillColor={'dark500'} cursor={'pointer'} />
          }
          title={
            <EditCreditCardModalTitle>
              Edit credit card
            </EditCreditCardModalTitle>
          }
          open={showEditCreditCardConfirmationModal}
          footer={null}
          onCancel={() => {
            if (creditCardFormRef && creditCardFormRef.current && creditCardFormRef.current.loading) {
              return false;
            }

            closeEditCreditCardFormModal();
          }}
        >
          <EditCreditCardForm
            creditCard={editCreditCard}
            ref={creditCardFormRef}
            onCreditCardSave={async (creditCardId) => {
              await refetchCreditCards(false);
              setSelectedCreditCardId(creditCardId);
            }}
            closeFormModal={closeEditCreditCardFormModal}
          />
        </EditCreditCardModal>
      )}

      <DeleteCreditCardModal
        maskClosable={false}
        width={335}
        title={
          <DeleteCreditCardModalTitle>
            Are you sure you want to delete your credit card?
          </DeleteCreditCardModalTitle>
        }
        open={showDeleteCreditCardConfirmationModal}
        footer={null}
        closable={false}
      >
        <DeleteCreditCardModalContent>
          <Button
            variant="secondary"
            disabled={deleteCreditCardButtonsDisabled}
            shape="circle"
            onClick={closeDeleteCreditCardConfirmationModal}
          >
            No
          </Button>
          <Button
            variant="primary"
            loading={deleteCreditCardButtonsDisabled}
            shape="circle"
            onClick={() => deleteCreditCard(selectedCreditCardId)}
          >
            Yes
          </Button>
        </DeleteCreditCardModalContent>
      </DeleteCreditCardModal>

      <ConsentModal
        maskClosable={false}
        closeIcon={
          <Icon name={'close'} fillColor={'dark500'} cursor={'pointer'} />
        }
        title={
          <ConsentModalTitle>
            Do you consent to ONTAB running a background check?
          </ConsentModalTitle>
        }
        open={showConsentModal}
        footer={null}
        onCancel={() => {
          if (consentButtonsDisabled) {
            return false;
          }

          closeConsentModal();
        }}
      >
        <ConsentModalContent>
          <Button
            variant="secondary"
            disabled={consentButtonsDisabled}
            shape="circle"
            onClick={closeConsentModal}
          >
            No
          </Button>
          <Button
            variant="primary"
            loading={consentButtonsDisabled}
            shape="circle"
            onClick={handleOnConfirm}
          >
            Yes
          </Button>
        </ConsentModalContent>
      </ConsentModal>
    </>
  );
};
