import React, { memo } from 'react';
import {
  SideNavbarDrawer,
  SideNavbarDrawerLinkItem,
  SideNavbarDrawerButtonItem,
  SideNavbarDrawerItem,
  SideNavbarDrawerLogo,
} from './styled';

import logo from '../../../assets/images/logo.png';
import { NavbarMenuItemProps } from '../Navbar';
import { Button } from '../Button';

export interface SideNavDrawerComponentProps {
  items?: NavbarMenuItemProps[];
  onClose?: () => void;
  onLogoClick?: () => void;
  open: boolean;
}

const SideNavDrawerComponent: React.FC<SideNavDrawerComponentProps> = ({
  items = [],
  onClose,
  onLogoClick,
  open = false,
}) => {
  const renderMenuItems = (menuItems: NavbarMenuItemProps[]) => {
    return menuItems.map((menuItem) => {
      if (menuItem.hide) {
        return null;
      }

      let Component = SideNavbarDrawerItem;

      if (menuItem.variant === 'button') {
        Component = SideNavbarDrawerButtonItem;
      } else if (menuItem.variant === 'link') {
        Component = SideNavbarDrawerLinkItem;
      }
      return (
        <Component key={menuItem.key} className={menuItem.className}>
          {menuItem.content}
        </Component>
      );
    });
  };

  return (
    <>
      <SideNavbarDrawer
        title={<SideNavbarDrawerLogo onClick={onLogoClick} src={logo} alt="" />}
        placement="left"
        open={open}
        closable={false}
        width={320}
        headerStyle={{ padding: '20px' }}
        bodyStyle={{ padding: 0 }}
        extra={
          <Button
            onClick={onClose}
            icon="close"
            iconVariant="medium"
            shape="circle"
            variant="secondary"
          />
        }
      >
        {renderMenuItems(items)}
      </SideNavbarDrawer>
    </>
  );
};

export const SideNavDrawer = memo(SideNavDrawerComponent);
