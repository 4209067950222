import styled, { CSSProp, css } from 'styled-components';
import { Card, Checkbox, Modal, Radio } from 'antd';
import { colors, typography } from '../../../../stories/foundation';
import { Icon } from '../../../../stories/atoms/Icon';
import { mediaBreakPointDown } from '../../../../stories/breakpoints';

export const AcceptTermsCheckbox = styled(Checkbox)`
  ${typography.p1};
  a {
    text-decoration: underline;
  }
`;

export const AddNewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-top: 45px;
  button {
    padding: 24px;
  }
`;

export const ProductPricingDetails = styled(Card)`
  border: 3px solid ${colors.light300};
  border-radius: 6px !important;
  ${typography.p1}
  font-weight: 500;
  line-height: 34px;
`;

export const CreditCardSelectionButtonCardIcon = styled(Icon)`
  margin-right: 12px;
`;

export const CreditCardSelectionCard = styled(Card)`
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 6px !important;
  background-color: ${colors.light300};
  .ant-card-body {
    width: 100%;
    padding: 15px;
  }
`;

export const CreditCardSelectionCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CreditCardSelectionCardFooter = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
`;

export const CreditCardDetails = styled.p`
  ${typography.p1}
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;

export const CreditCardBrand = styled.span`
  > svg {
    width: 45px;
    height: 45px;
  }
`;

export const CreditCardEditIcon = styled(Icon)`
  position: absolute;
  right: 28px;
  top: 6px;
  width: 18px;
  height: 18px;
`;

export const CreditCardRemoveIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 2px;
`;

export const CreditCardSelectionRadio = styled(Radio)``;

export const CreditCardSelectionRadioButtons = styled(
  CreditCardSelectionRadio.Group
)`
  display: flex;
  gap: 30px;
  flex-direction: column;
  ${mediaBreakPointDown(
    'sm',
    `
      gap: 18px;
    `
  )}
  > label {
    transition: background-color 0.3s ease;
    background-color: ${colors.light300};
    display: flex;
    padding: 13px;
    height: 100%;
    border-radius: 6px !important;
    border: none !important;
    > span:nth-child(2) {
      flex: 1;
    }
    &::before {
      content: none !important;
    }
    &.ant-radio-button-wrapper {
      > .ant-radio-button {
        position: relative;
        z-index: 0;
        flex: 0;
        top: 37px;
        > input[type='radio'] {
          opacity: revert;
          width: 20px;
          height: 20px;
        }
      }
    }
    &.ant-radio-button-wrapper-checked {
      border: 1px solid ${colors.primaryText} !important;
      box-shadow: 0 0 8px 2px ${colors.light500};
      ${CreditCardSelectionCard} {
        background-color: ${colors.white};
      }
      ${CreditCardSelectionButtonCardIcon} {
        color: ${colors.primaryText};
      }
      background-color: ${colors.white};
    }
  }
`;

const modalStyles = css`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    background-color: ${colors.light300};
    .ant-modal-title > div {
      color: ${colors.dark500};
    }
  }

  .ant-modal-close {
    margin-top: 8px;
  }
`;

const modalTitleStyles = css`
  display: flex;
  align-items: center;
  ${typography.h4}
  color: ${colors.white};
`;

export const AddNewCreditCardModal = styled(Modal)`
  ${modalStyles}
`;

export const EditCreditCardModal = styled(Modal)`
  ${modalStyles}
`;

export const DeleteCreditCardModal = styled(Modal)`
  ${modalStyles}
`;

export const AddNewCreditCardModalTitle = styled.div`
  ${modalTitleStyles}
`;

export const EditCreditCardModalTitle = styled.div`
  ${modalTitleStyles}
`;

export const DeleteCreditCardModalTitle = styled.div`
  ${modalTitleStyles}
`;

export const DeleteCreditCardModalContent = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  button {
    padding: 10px 35px;
  }
`;

type EmptyCardProps = {
  $info?: boolean;
  $error?: boolean;
};

export const EmptyCard = styled<EmptyCardProps>(Card)`
  ${typography.p1};
  border: none;
  border-radius: 6px !important;
  background-color: ${colors.light300};
  ${({ $info }: EmptyCardProps): CSSProp | false =>
    !!$info &&
    `
      background-color: ${colors.blue300};
      color: ${colors.light50};
    `}
  ${({ $error }: EmptyCardProps): CSSProp | false =>
    !!$error &&
    `
      background-color: ${colors.red300};
      color: ${colors.light50};
    `}
  .ant-card-body {
    width: 100%;
    padding: 15px 20px;
  }
`;

export const ConsentModal = styled(Modal)`
  ${modalStyles}
`;

export const ConsentModalTitle = styled.div`
  ${modalTitleStyles}
`;

export const ConsentModalContent = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  button {
    padding: 10px 35px;
  }
`;
