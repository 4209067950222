import { CLIENT_ID, CLIENT_SECRET } from '../../../../../env.js';
import React from 'react';

import {
  AuthWrapper as LoginWrapper,
  AuthContent as LoginContent,
  AuthContentLeft as LoginContentLeft,
  AuthContentRight as LoginContentRight,
  AuthFormContent as LoginFormContent,
  AuthLinksWrapper as LoginLinksWrapper,
  PageFullBodyStyle,
} from '../styled';
import { LoginButtonWrapper } from './styled';

import { Col, Divider, Form, Row } from 'antd';
import { notification } from '../../../helpers';
import { Button, Container } from 'ov-components';
import { Link, useNavigate } from 'react-router-dom';
import { FormItemInput } from 'ov-components';
import useAxiosHttClient from '../../../config/http_client';
import { useAuth } from '../../../utils/hooks/useAuth';
import { displayApiErrors } from '../../../helpers/error_messages_helpers';

const Login = () => {
  const navigate = useNavigate();
  const [signInUser, , setUserProfileOnAccount] = useAuth();

  const [{ loading }, executeSignIn] = useAxiosHttClient(
    {
      url: '/login/token',
      method: 'post',
    },
    { manual: true }
  );

  const submitLogin = async (values: any) => {
    try {
      const response = await executeSignIn({
        data: {
          grant_type: 'password',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          email: values.email,
          password: values.password,
        },
      });

      signInUser(response.data.access_token, response.data.user.data);
      if (response.data.user.data.attributes.profile.data) {
        setUserProfileOnAccount(
          response.data.user.data.attributes.profile.data
        );
      }
      navigate('/');
      notification.success({
        message: 'Logged in successfully',
      });
    } catch (error) {
      displayApiErrors(error);
    }
  };

  return (
    <>
      <PageFullBodyStyle />
      <LoginWrapper>
        <LoginContent>
          <LoginContentLeft>
            <Container>
              <LoginFormContent>
                <h3>Sign In</h3>
                <h2>Welcome Back!</h2>
              </LoginFormContent>
              <Form
                name="login"
                layout={'vertical'}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={submitLogin}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email!' },
                    {
                      pattern: /^\S+@\S+\.\S+$/,
                      message: 'Email is not valid!',
                    },
                  ]}
                >
                  <FormItemInput />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: 'Please enter your password!' },
                  ]}
                >
                  <FormItemInput variant="password" />
                </Form.Item>
                <LoginButtonWrapper>
                  <Row justify="space-between">
                    <Col md={16} xs={24}>
                      {'Forgotten your password? '}
                      <Link to={'/password/reset'}>Click here</Link>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item>
                        <Button
                          variant="primary"
                          shape="circle"
                          disabled={loading}
                          block
                        >
                          Sign In
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </LoginButtonWrapper>
              </Form>
              <Divider />
              <LoginLinksWrapper>
                {"Don't have an account? "}
                <Link to={'/sign-up'}>Sign Up</Link>
              </LoginLinksWrapper>
            </Container>
          </LoginContentLeft>
          <LoginContentRight />
        </LoginContent>
      </LoginWrapper>
    </>
  );
};

export default Login;
