import styled from 'styled-components';
import { mediaBreakPointDown } from '../../../stories/breakpoints';

export const LoginButtonWrapper = styled.div`
  margin-top: 50px;

  ${mediaBreakPointDown(
    'xs',
    `
    text-align: center;
    & > div {
      gap: 10px;
    }
  `
  )};
`;
