import React, { memo, ReactNode } from 'react';
import {
  NavbarWrapper,
  NavbarLogo,
  NavbarHeader,
  NavbarMenuContainer,
  NavbarMenu,
  NavbarMenuItem,
  NavbarButtonItem,
  NavbarLinkItem,
  SideNavbarButton,
} from './styled';

import appLogo from '../../../assets/images/logo.png';
import appInvertedLogo from '../../../assets/images/logo_inverted.png';
import { Container } from 'ov-components';

export interface NavbarMenuItemProps {
  key: string;
  content: ReactNode | string;
  variant?: 'button' | 'link' | 'item';
  className?: string;
  position?: 'left' | 'right';
  hide?: boolean;
}

export interface NavbarComponentProps {
  items?: NavbarMenuItemProps[];
  logoInverted?: boolean;
  fillIn?: boolean;
  onSideNavbarButtonClick?: () => void;
  onLogoClick?: () => void;
}

const NavbarComponent: React.FC<NavbarComponentProps> = ({
  items = [],
  logoInverted = false,
  fillIn = false,
  onSideNavbarButtonClick,
  onLogoClick,
}) => {
  const menuItems = items.map((item) => {
    return {
      ...item,
      variant: item.variant || 'link',
      position: item.position || 'left',
    };
  });

  const renderMenuItems = (menuItems: NavbarMenuItemProps[]) => {
    return menuItems.map((menuItem) => {
      if (menuItem.hide) {
        return null;
      }

      let Component = NavbarMenuItem;

      if (menuItem.variant === 'button') {
        Component = NavbarButtonItem;
      } else if (menuItem.variant === 'link') {
        Component = NavbarLinkItem;
      }
      return (
        <Component
          key={menuItem.key}
          className={menuItem.className}
          fillIn={fillIn}
        >
          {menuItem.content}
        </Component>
      );
    });
  };

  const leftNewMenuItems = menuItems.filter((item) => item.position === 'left');
  const rightNewMenuItems = menuItems.filter(
    (item) => item.position === 'right'
  );

  return (
    <>
      <NavbarWrapper fillIn={fillIn}>
        <Container>
          <NavbarHeader>
            <NavbarLogo
              onClick={onLogoClick}
              logoInverted={logoInverted}
              src={logoInverted ? appInvertedLogo : appLogo}
            />
            <NavbarMenuContainer>
              <NavbarMenu>{renderMenuItems(leftNewMenuItems)}</NavbarMenu>
              <NavbarMenu>{renderMenuItems(rightNewMenuItems)}</NavbarMenu>
              <SideNavbarButton
                icon="menu"
                variant="secondary"
                iconVariant="medium"
                shape="circle"
                onClick={onSideNavbarButtonClick}
              />
            </NavbarMenuContainer>
          </NavbarHeader>
        </Container>
      </NavbarWrapper>
    </>
  );
};

export const Navbar = memo(NavbarComponent);
