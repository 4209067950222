import styled from 'styled-components';
import React from 'react';
import { colors } from '../../../stories/foundation';
import { mediaBreakPointSingle } from '../../../stories/breakpoints';

export const ThankYouWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin-top: -115px;
  ${mediaBreakPointSingle(
    'xmd',
    `
    margin-top: -30px;
    height: 100%;
  `
  )}
`;

export const ThankYouContent = styled.div`
  text-align: center;
`;

export const ThankYouLinks = styled.div`
  margin-top: 35px;
`;

export const ThankYouImageWrapper = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${colors.light300};
  border-radius: 95px;
  margin-bottom: 15px;
`;

export const ThankYouImage = (): React.ReactElement => (
  <svg viewBox="0 0 40 40" style={{ width: '150px', height: '150px' }}>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="14.47"
        y1="19.46"
        x2="38"
        y2="19.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={'#2252b3'} />
        <stop offset="1" stopColor={'#3075ff'} />
      </linearGradient>
      <linearGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_37"
        x1="2"
        y1="22.41"
        x2="22.16"
        y2="22.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={'#ff6279'} />
        <stop offset="1" stopColor={'#ffad60'} />
      </linearGradient>
    </defs>
    <g id="Layer_1-2">
      <g>
        <g>
          <rect
            style={{ 'fill': '#1d4699' }}
            x="28.41"
            y="27.22"
            width="5.55"
            height="10"
          />
          <path
            style={{ 'fill': 'url(#linear-gradient)' }}
            d="M14.47,9.9H31.83c3.4,0,6.17,2.76,6.17,6.17v12.95H14.47V9.9h0Z"
          />
          <path
            style={{ 'fill': '#98baff' }}
            d="M12,9.9h3.32c3.82,0,6.92,3.1,6.92,6.92v12.2H5.09v-12.2c0-3.82,3.1-6.92,6.92-6.92Z"
          />
          <path
            style={{ 'fill': '#1d4699' }}
            d="M27.61,17.9c-.24,0-.43-.19-.43-.43V4.63c0-.24,.19-.43,.43-.43s.43,.19,.43,.43v12.84c0,.24-.19,.43-.43,.43Z"
          />
          <circle
            style={{ 'fill': '#98baff' }}
            cx="27.6"
            cy="17.46"
            r="2"
            transform="translate(5.95 41.91) rotate(-80.78)"
          />
          <path
            style={{ 'fill': 'url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_37)' }}
            d="M22.12,18.22v10.86H2V15.75l3.1,1.69,7.26,3.97,.92,.5,.91-.5,7.96-4.36c.02,.14-.03,.27-.01,.41,.02,.25-.03,.5-.03,.75Z"
          />
          <polygon
            style={{ 'fill': '#ffad60' }}
            points="22.12 27.33 22.12 29.07 2.04 29.07 5.09 26.8 11.06 22.4 12.38 21.43 13.29 20.75 14.2 21.42 15.45 22.39 22.12 27.33"
          />
          <path
            style={{ 'fill': '#e6586d' }}
            d="M22.16,17.05l-6.65,5.33-1.36,1c-.52,.38-1.22,.38-1.73,0l-1.36-1-5.97-4.33-3.05-2.19H22.12l.04,1.19Z"
          />
          <polygon
            style={{ 'fill': '#e6586d' }}
            points="36.6 5.05 27.1 7.32 27.1 2.78 36.6 5.05"
          />
        </g>
        <rect style={{ 'fill': 'none' }} width="40" height="40" />
      </g>
    </g>
  </svg>
);
