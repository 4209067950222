import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BackgroundCheckRequestContainer,
  BackgroundCheckRequestHeader,
  BackgroundCheckRequestLoader,
  BackgroundCheckRequestTitle,
  BackgroundCheckRequestWrapper,
} from './styled';
import { Steps as WizardSteps } from './Steps';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ProtectedStep } from './Steps/ProtectedStep';
import { Reason } from './Reason';
import { CandidateInfo } from './CandidateInfo';
import { SelfDeclaration } from './SelfDeclaration';
import { Payment } from './Payment';
import { IdentityVerification } from './IdentityVerification';
import { Finish } from './Finish';
import useAxiosHttClient from '../../../config/http_client';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../utils/constants';
import { BackgroundCheckRequestStepsEnum } from '../../../api_types/enums';
import { BackgroundCheck } from '../../../api_types';
import moment from 'moment/moment';
import {
  BackgroundCheckRequestStatusEnum,
  BackgroundCheckRequestType,
  DEFAULT_BACKGROUND_CHECK_REQUEST,
  StepsContextType,
} from './types';
import { isEmpty } from 'lodash';
import { getStepsBeforePayment } from '../../../helpers/background_check_steps_helpers';

export const StepsContext = createContext({} as StepsContextType);

interface ProviderProps {
  value: StepsContextType;
  children?: ReactNode;
}

export function useBackgroundCheckRequestSteps() {
  return useContext(StepsContext);
}

const BackgroundCheckRequestProvider: React.FC<ProviderProps> = (props) => {
  const { value, children } = props;
  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};

export const tabRoutes = {
  [BackgroundCheckRequestStepsEnum.Reason]:
    ROUTES.backgroundCheckRequest.reason,
  [BackgroundCheckRequestStepsEnum.CandidateInformation]:
    ROUTES.backgroundCheckRequest.candidateInformation,
  [BackgroundCheckRequestStepsEnum.SelfDeclaration]:
    ROUTES.backgroundCheckRequest.selfDeclaration,
  [BackgroundCheckRequestStepsEnum.Payment]:
    ROUTES.backgroundCheckRequest.payment,
  [BackgroundCheckRequestStepsEnum.IdVerification]:
    ROUTES.backgroundCheckRequest.idVerification,
};

export const findStepFromUrl = (url: string) => {
  const route = Object.entries(tabRoutes).find((route) =>
    url.includes(route[1])
  );
  if (route) {
    return route[0];
  }

  return;
};

export const BackgroundCheckRequest: React.FC = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [steps, setSteps] = useState<Array<BackgroundCheckRequestStepsEnum>>(
    []
  );
  const [disabledSteps, setDisabledSteps] = useState<
    Array<BackgroundCheckRequestStepsEnum>
  >([]);
  const [activeStep, setActiveStep] =
    useState<BackgroundCheckRequestStepsEnum>();
  const [backgroundCheckRequest, setBackgroundCheckRequest] =
    useState<BackgroundCheckRequestType>({});

  const [
    { data: backgroundCheckResponse, loading: backgroundCheckApiLoading },
  ] = useAxiosHttClient({
    url: '/background_check',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  const backgroundCheck: BackgroundCheck = backgroundCheckResponse && backgroundCheckResponse.data;

  useEffect(() => {
    const stepFromUrl = findStepFromUrl(pathname);
    if (stepFromUrl) {
      setActiveStep(stepFromUrl as BackgroundCheckRequestStepsEnum);
    }
  }, []);

  useEffect(() => {
    if (!backgroundCheck) {
      setBackgroundCheckRequest(DEFAULT_BACKGROUND_CHECK_REQUEST);
      return;
    }

    setBackgroundCheckRequest({
      id: backgroundCheck.id,
      reason: backgroundCheck.attributes.reason,
      consent: backgroundCheck.attributes.consent,
      criminalRecord: backgroundCheck.attributes.criminalRecord,
      previousRecords: backgroundCheck.attributes.previousRecords && backgroundCheck.attributes.previousRecords.map(
        (previousRecord) => {
          return {
            offense: previousRecord.offense,
            dateOfSentence: previousRecord.date_of_sentence
              ? moment(previousRecord.date_of_sentence)
              : null,
            courtLocation: previousRecord.court_location,
            description: previousRecord.description,
          };
        }
      ),
      status: backgroundCheck.attributes.status,
      paymentStatus: backgroundCheck.attributes.paymentStatus,
      currentStep: backgroundCheck.attributes.currentStep,
      applicationReferenceId: backgroundCheck.attributes.applicationReferenceId,
      reportUrl: backgroundCheck.attributes.reportUrl,
      completed: !!backgroundCheck.attributes.finishedAt,
    });

    setSteps(backgroundCheck.attributes.completedSteps);
    if (!activeStep) {
      navigate(`${tabRoutes[backgroundCheck.attributes.currentStep]}${search}`);
      setActiveStep(backgroundCheck.attributes.currentStep);
    }

    if (
      backgroundCheck.attributes.currentStep ===
      BackgroundCheckRequestStepsEnum.IdVerification
    ) {
      setDisabledSteps(getStepsBeforePayment());
    }
  }, [backgroundCheck]);

  const showFinishedView = backgroundCheckRequest.completed;

  useEffect(() => {
    if (showFinishedView) {
      navigate(ROUTES.backgroundCheckRequest.finish);
    }
  }, [showFinishedView]);

  const backgroundCheckLoading =
    backgroundCheckApiLoading || isEmpty(backgroundCheckRequest);

  const wizardStepsView = (
    <>
      <BackgroundCheckRequestHeader>
        <BackgroundCheckRequestTitle>
          New Background Check Request
        </BackgroundCheckRequestTitle>
      </BackgroundCheckRequestHeader>

      <WizardSteps />

      <BackgroundCheckRequestContainer>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={ROUTES.backgroundCheckRequest.reason} />}
          />
          <Route
            path={ROUTES.backgroundCheckRequest.reason.replace(
              'background-check-request/',
              ''
            )}
            element={<Reason />}
          />
          <Route
            path={ROUTES.backgroundCheckRequest.candidateInformation.replace(
              'background-check-request/',
              ''
            )}
            element={
              <ProtectedStep
                step={BackgroundCheckRequestStepsEnum.CandidateInformation}
              >
                <CandidateInfo />
              </ProtectedStep>
            }
          />
          <Route
            path={ROUTES.backgroundCheckRequest.selfDeclaration.replace(
              'background-check-request/',
              ''
            )}
            element={
              <ProtectedStep
                step={BackgroundCheckRequestStepsEnum.SelfDeclaration}
              >
                <SelfDeclaration />
              </ProtectedStep>
            }
          />
          <Route
            path={ROUTES.backgroundCheckRequest.payment.replace(
              'background-check-request/',
              ''
            )}
            element={
              <ProtectedStep step={BackgroundCheckRequestStepsEnum.Payment}>
                <Payment />
              </ProtectedStep>
            }
          />
          <Route
            path={ROUTES.backgroundCheckRequest.idVerification.replace(
              'background-check-request/',
              ''
            )}
            element={
              <ProtectedStep
                step={BackgroundCheckRequestStepsEnum.IdVerification}
              >
                <IdentityVerification />
              </ProtectedStep>
            }
          />
        </Routes>
      </BackgroundCheckRequestContainer>
    </>
  );

  const finishedView = (
    <Routes>
      <Route
        path={ROUTES.backgroundCheckRequest.finish.replace(
          'background-check-request/',
          ''
        )}
        element={<Finish />}
      />
    </Routes>
  );

  if (backgroundCheckLoading) {
    return <BackgroundCheckRequestLoader size={'large'} />;
  }

  const view = showFinishedView ? finishedView : wizardStepsView;

  return (
    <BackgroundCheckRequestWrapper>
      <BackgroundCheckRequestProvider
        value={[
          steps,
          setSteps,
          activeStep,
          setActiveStep,
          backgroundCheckRequest,
          setBackgroundCheckRequest,
          disabledSteps,
          setDisabledSteps,
        ]}
      >
        {view}
      </BackgroundCheckRequestProvider>
    </BackgroundCheckRequestWrapper>
  );
};
