import { Drawer } from 'antd';
import styled, { css } from 'styled-components';
import { colors } from '../../foundation';

export const SideNavbarDrawer = styled(Drawer)`
  div:last-child {
    border-bottom: 0;
  }
`;

const sidebarDrawerMenuItemStyles = css`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.border};
  color: ${colors.text};
  transition: color 1s ease, background-color 0.3s ease;
`;

const sidebarDrawerMenuActiveItemStyles = css`
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background-color: ${colors.transparent};
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  &:hover,
  &.active {
    background-color: ${colors.light300};
    color: ${colors.hyperlinkText};
    &::after {
      background-color: ${colors.blue500};
    }
  }
`;

export const SideNavbarDrawerItem = styled.div`
  cursor: pointer;
  padding: 20px;
  ${sidebarDrawerMenuItemStyles}
  ${sidebarDrawerMenuActiveItemStyles}
`;

export const SideNavbarDrawerLinkItem = styled.div`
  ${sidebarDrawerMenuItemStyles}
  a {
    width: 100%;
    color: ${colors.text};
    padding: 20px;
    ${sidebarDrawerMenuActiveItemStyles}
  }
`;

export const SideNavbarDrawerButtonItem = styled(SideNavbarDrawerItem)`
  &:hover {
    background-color: ${colors.transparent};
  }
  button {
    width: 100%;
  }
`;

export const SideNavbarDrawerLogo = styled.img`
  cursor: pointer;
  width: 110px;
`;
