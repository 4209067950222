import { CARD_ICONS } from './constants';
import React from 'react';

export const CardIcon = ({ cardType }): JSX.Element => {
  if (cardType) {
    const Icon = CARD_ICONS[cardType];
    return <Icon />;
  }

  return <></>;
};
