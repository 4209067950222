import { Navbar, SideNavDrawer, Button, Container } from 'ov-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import React, { useEffect, useState } from 'react';
import { NavbarMenuItemProps } from '../../stories/atoms/Navbar';
import { useAccount } from '../../context';
import { AppBody, AppContainer } from '../App/styled';
import Footer from '../Footer';

export const HomeLayout = () => {
  const [account] = useAccount();
  const { authenticated } = account;
  const location = useLocation();
  const navigate = useNavigate();
  const [sideNavMenuVisible, setSideNavMenuVisible] = useState(false);
  const [invertedLogo, setInvertedLogo] = useState(true);
  const [navbarFillIn, setNavbarFillIn] = useState(false);

  useEffect(() => {
    setSideNavMenuVisible(false);
  }, [location]);

  const rootPath = location.pathname === '/' || location.pathname === '/home';

  useEffect(() => {
    if (!rootPath) {
      return;
    }

    const handleNavbar = (scrollY) => {
      if (rootPath) {
        const scrolled = scrollY > 0;
        setInvertedLogo(!scrolled);
        setNavbarFillIn(scrolled);
      }
    };

    const onScroll = () => handleNavbar(window.scrollY);
    window.removeEventListener('scroll', handleNavbar);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleNavbar);
  }, [rootPath]);

  const menuItems: NavbarMenuItemProps[] = [
    {
      key: 'loginButton',
      content: (
        <Button
          onClick={() => navigate('/login')}
          variant="secondary"
          shape="circle"
        >
          Login
        </Button>
      ),
      variant: 'button',
      position: 'right',
      hide: authenticated,
    },
    {
      key: 'startBackGroundCheckButton',
      content: (
        <Button
          onClick={() => navigate('/sign-up')}
          variant="primary"
          shape="circle"
        >
          Start a Background Check
        </Button>
      ),
      variant: 'button',
      position: 'right',
      hide: authenticated,
    },
  ];

  const renderNavbar = (): JSX.Element => {
    if (rootPath) {
      return (
        <Navbar
          items={menuItems}
          fillIn={navbarFillIn}
          logoInverted={invertedLogo}
          onSideNavbarButtonClick={() => setSideNavMenuVisible(true)}
          onLogoClick={() => navigate('/')}
        />
      );
    }

    return (
      <Navbar
        items={menuItems}
        fillIn={true}
        onSideNavbarButtonClick={() => setSideNavMenuVisible(true)}
        onLogoClick={() => navigate('/')}
      />
    );
  };

  return (
    <>
      <AppBody>
        {renderNavbar()}

        <AppContainer>
          <Outlet />
        </AppContainer>

        <SideNavDrawer
          onLogoClick={() => navigate('/')}
          open={sideNavMenuVisible}
          onClose={() => setSideNavMenuVisible(false)}
          items={menuItems}
        />
      </AppBody>
      <Footer />
    </>
  );
};
