import styled from 'styled-components';
import { mediaBreakPointUp } from '../../breakpoints';

export const ContainerWrapper = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  ${mediaBreakPointUp(
    'sm',
    `
    max-width: 540px;
  `
  )}

  ${mediaBreakPointUp(
    'md',
    `
    max-width: 720px;
  `
  )}

  ${mediaBreakPointUp(
    'lg',
    `
    max-width: 960px;
  `
  )}
  ${mediaBreakPointUp(
    'xl',
    `
    max-width: 1140px;
  `
  )}

  ${mediaBreakPointUp(
    'xxl',
    `
    max-width: 1320px;
  `
  )}
`;
