import styled from 'styled-components';
import { Tabs } from 'antd';
import { colors, typography } from '../../../../stories/foundation';
import { mediaBreakPointDown } from '../../../../stories/breakpoints';

export const StepsWrapper = styled.div`
  margin: 45px 0;
  ${mediaBreakPointDown(
    'sm',
    `
    margin: 30px 0;
  `
  )}
`;

export const StepTabs = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      border-bottom: 0;
    }

    .ant-tabs-ink-bar {
      height: 3px;
      background-color: ${colors.primaryText};
    }

    .ant-tabs-nav-wrap {
      white-space: normal !important;
    }

    .ant-tabs-tab {
      width: 195px;
      word-break: break-word;
      > div {
        width: 100%;
        text-align: center;
      }
      &:not(.ant-tabs-tab-disabled) {
        &:hover,
        > div:hover {
          color: ${colors.primaryText};
        }
      }
      &:not(.ant-tabs-tab-active) {
        border-bottom: 3px solid ${colors.light500};
      }
      &.ant-tabs-tab-active {
        border-bottom: 3px solid transparent;
        > div {
          color: ${colors.primaryText};
        }
      }
      & + .ant-tabs-tab {
        margin-left: 24px;
        ${mediaBreakPointDown(
          'sm',
          `
          margin-left: 10px;
        `
        )}
      }
    }

    .ant-tabs-tab {
      ${mediaBreakPointDown(
        'sm',
        `
          width: 50px;
          padding: 12px;
        `
      )}
      > div {
        ${mediaBreakPointDown(
          'sm',
          `
            display: none;
          `
        )}
      }
    }
  }
`;

export const StepActiveTabTextForMobile = styled.div`
  display: none;
  ${mediaBreakPointDown(
    'sm',
    `
    display: block;
    color: ${colors.primaryText};
    text-align: center;
    margin-top: 45px;
    ${typography.p1Bold}
  `
  )}
`;
