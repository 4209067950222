import { BackgroundCheckRequestStepsEnum } from '../api_types/enums';

export const getStepsBeforePayment = (): BackgroundCheckRequestStepsEnum[] => {
  return [
    BackgroundCheckRequestStepsEnum.Reason,
    BackgroundCheckRequestStepsEnum.CandidateInformation,
    BackgroundCheckRequestStepsEnum.SelfDeclaration,
    BackgroundCheckRequestStepsEnum.Payment,
  ];
};
