import React from 'react';

const EditIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M17.9,3.6c-0.4-0.4-1-0.4-1.4,0L15,5l4.2,4.2l1.4-1.4c0.4-0.4,0.4-1,0-1.4L17.9,3.6z M17.9,10.7l-4.2-4.2 l-8.9,8.9c-0.2,0.2-0.3,0.4-0.3,0.7v2.8c0,0.6,0.4,1,1,1h2.8c0.3,0,0.5-0.1,0.7-0.3L17.9,10.7z"
    />
  </svg>
);

export default EditIcon;
