import React, { useEffect, useState } from 'react';
import {
  BackgroundCheckRequestActions,
  BackgroundCheckRequestConfirmButton,
  BackgroundCheckRequestPreviousLink,
  StepSection,
  StepTitle,
} from '../styled';
import { Col, Form, Row } from 'antd';
import { FormItemInput } from 'ov-components';
import { FormItemSelect } from '../../../../stories/molecules/FormSelectInput';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../../../stories/atoms/Icon';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useAccount } from '../../../../context';
import moment from 'moment';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { notification } from '../../../../helpers';
import { uniq } from 'lodash';
import { CandidateInfoFormValues } from './types';

export const PHONE_NUMBER_MASK = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CandidateInfo: React.FC = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [account, , , , userProfileId] = useAccount();
  const { user } = account;
  const [, , setUserProfileOnAccount] = useAuth();
  const [steps, setSteps, , setActiveStep] = useBackgroundCheckRequestSteps();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<
    CandidateInfoFormValues | undefined
  >();
  const [countryOptions, setCountryOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [provinceOptions, setProvinceOptions] = useState<
    { label: string; options: any }[]
  >([]);

  const [{ data: regionalCodes }] = useAxiosHttClient({
    url: '/regional_codes',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (user && !formValues) {
    setFormValues({
      first_name: user.firstName,
      last_name: user.lastName,
      sex: user.sex,
      date_of_birth: user.dateOfBirth ? moment(user.dateOfBirth) : null,
      phone_number: user.phoneNumber,
      birth_country: user.birthCountry,
      home_address_attributes: {
        ...user.address,
        country: 'Canada',
      },
    });
  }

  useEffect(() => {
    if (!regionalCodes) {
      return;
    }

    const availableCountryOptions = Object.keys(regionalCodes.countries).map(
      (countryName) => {
        return {
          label: countryName,
          value: regionalCodes.countries[countryName],
        };
      }
    );

    setCountryOptions(availableCountryOptions);

    const canadaProvinceOptions = Object.keys(
      regionalCodes.canada_provinces
    ).map((provinceName) => {
      return {
        label: provinceName,
        value: regionalCodes.canada_provinces[provinceName],
      };
    });

    setProvinceOptions([
      {
        label: 'Canada Province or States',
        options: canadaProvinceOptions,
      },
    ]);
  }, [regionalCodes]);

  const [{ loading: createProfileApiLoading }, createProfileApi] =
    useAxiosHttClient(
      {
        url: '/users/profile',
        method: 'POST',
      },
      { manual: true }
    );

  const [{ loading: updateProfileApiLoading }, updateProfileApi] =
    useAxiosHttClient(
      {
        url: '/users/profile',
        method: 'PUT',
      },
      { manual: true }
    );

  const [
    { loading: updateBackgroundCheckRequestApiLoading },
    updateBackgroundCheckRequestApi,
  ] = useAxiosHttClient(
    {
      url: '/background_check',
      method: 'PUT',
    },
    { manual: true }
  );

  const loading =
    createProfileApiLoading ||
    updateProfileApiLoading ||
    updateBackgroundCheckRequestApiLoading;

  const handleSubmit = async (values: CandidateInfoFormValues) => {
    const profileApi = userProfileId ? updateProfileApi : createProfileApi;
    try {
      const addressCountry = values.home_address_attributes && values.home_address_attributes.country;
      const profileApiResponse = await profileApi({
        data: {
          ...values,
          home_address_attributes: {
            ...values.home_address_attributes,
            country: addressCountry
              ? regionalCodes.countries[addressCountry]
              : undefined,
          },
          date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      const profile = profileApiResponse.data;
      setUserProfileOnAccount(profile.data);

      const completedSteps = uniq([
        ...steps,
        BackgroundCheckRequestStepsEnum.SelfDeclaration,
      ]);
      const currentStep = BackgroundCheckRequestStepsEnum.SelfDeclaration;

      await updateBackgroundCheckRequestApi({
        data: {
          completed_steps: completedSteps,
          current_step: currentStep,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      setSteps(completedSteps);
      setActiveStep(currentStep);
      navigate(tabRoutes[currentStep]);
    } catch (error) {
      notification.error({ message: 'Something went wrong' });
    }
  };
  return (
    <>
      <Form
        form={form}
        name="candidateInfoForm"
        layout={'vertical'}
        initialValues={formValues}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <StepSection>
          <StepTitle>Please confirm your personal information</StepTitle>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name!',
                  },
                ]}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Middle Name (Optional)" name="middle_name">
                <FormItemInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name!',
                  },
                ]}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Second Last Name (Optional)"
                name="second_last_name"
              >
                <FormItemInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Sex"
                name="sex"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your sex!',
                  },
                ]}
              >
                <FormItemSelect
                  items={[
                    {
                      label: 'Male',
                      value: 'male',
                    },
                    {
                      label: 'Female',
                      value: 'female',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number!',
                  },
                ]}
              >
                <FormItemInput
                  variant={'mask'}
                  maskPattern={PHONE_NUMBER_MASK}
                  maskGuide={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Date of Birth"
                name="date_of_birth"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your date of birth!',
                  },
                ]}
              >
                <FormItemInput variant="datepicker" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Birth Country"
                name="birth_country"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your birth country!',
                  },
                ]}
              >
                <FormItemSelect search items={countryOptions} />
              </Form.Item>
            </Col>
          </Row>
        </StepSection>

        <StepSection divider style={{ 'marginBottom': 0 }}>
          <StepTitle>Please confirm your address:</StepTitle>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Address Line 1"
                name={['home_address_attributes', 'line1']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your address line 1!',
                  },
                ]}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Address Line 2"
                name={['home_address_attributes', 'line2']}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Country"
                name={['home_address_attributes', 'country']}
              >
                <FormItemInput disabled />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Province"
                name={['home_address_attributes', 'state']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your province!',
                  },
                ]}
              >
                <FormItemSelect search groupOptions={provinceOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="City"
                name={['home_address_attributes', 'city']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your city!',
                  },
                ]}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Postal Code"
                name={['home_address_attributes', 'zipcode']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your postal code!',
                  },
                ]}
              >
                <FormItemInput />
              </Form.Item>
            </Col>
          </Row>
        </StepSection>
        <Form.Item shouldUpdate>
          {() => {
            return (
              <BackgroundCheckRequestActions>
                <BackgroundCheckRequestPreviousLink
                  to={tabRoutes[BackgroundCheckRequestStepsEnum.Reason]}
                >
                  <Icon name={'left'} /> Go to previous
                </BackgroundCheckRequestPreviousLink>
                <BackgroundCheckRequestConfirmButton
                  variant="primary"
                  shape="circle"
                  disabled={
                    loading ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  Confirm and Continue
                </BackgroundCheckRequestConfirmButton>
              </BackgroundCheckRequestActions>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
};
