// TODO: Remove these sizes
export const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

// These sizes are taken from bootstrap
// https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
//
export type Breakpoint =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'xmd'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl';

export const breakpoints: { [key in Breakpoint]: number } = {
  xxs: 0,
  xs: 428,
  sm: 576,
  md: 768,
  xmd: 844,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1900,
};

export const nextBreakPoint = (breakpoint) => {
  const breakpointNames = Object.keys(breakpoints);
  const breakpointIndex = breakpointNames.findIndex((names) =>
    names.includes(breakpoint)
  );
  const nextBreakpoint = breakpointNames[breakpointIndex + 1];
  if (nextBreakpoint) {
    return breakpoints[nextBreakpoint];
  }

  return 0;
};

export const singleBreakpointFactor = 0.02;
