import React, { useEffect, useState } from 'react';
import { Select } from '../../../../stories/atoms/Select';
import {
  BackgroundCheckRequestActions,
  BackgroundCheckRequestConfirmButton,
  StepTitle,
} from '../styled';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import { useNavigate } from 'react-router-dom';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';
import useAxiosHttClient from '../../../../config/http_client';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../utils/constants';
import { notification } from '../../../../helpers';
import { Input } from 'ov-components';
import { OtherReasonInputGroup } from './styled';
import { uniq } from 'lodash';

const OTHER_REASON = 'other';

const REASONS = [
  { label: 'Pre-employment requirement', value: 'pre_employment_requirement' },
  { label: 'Pre-license requirement', value: 'pre_license_requirement' },
  { label: 'School requirement', value: 'school_requirement' },
  { label: 'Other (please specify)', value: OTHER_REASON },
];

export const Reason: React.FC = () => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const navigate = useNavigate();
  const [
    steps,
    setSteps,
    ,
    setActiveStep,
    backgroundCheckRequest,
    setBackgroundCheckRequest,
  ] = useBackgroundCheckRequestSteps();
  const [reason, setReason] = useState<string | undefined>();
  const [otherReason, setOtherReason] = useState<string | undefined>();
  const [showOtherReason, setShowOtherReason] = useState(false);

  useEffect(() => {
    if (!backgroundCheckRequest.reason) {
      return;
    }

    if (REASONS.some((item) => item.value === backgroundCheckRequest.reason)) {
      setReason(backgroundCheckRequest.reason);
      if (backgroundCheckRequest.reason === OTHER_REASON) {
        setOtherReason(OTHER_REASON);
        setShowOtherReason(true);
      }
      return;
    }

    setReason(OTHER_REASON);
    setOtherReason(backgroundCheckRequest.reason);
    setShowOtherReason(true);
  }, [backgroundCheckRequest]);

  const [
    { loading: createBackgroundCheckRequestApiLoading },
    createBackgroundCheckRequestApi,
  ] = useAxiosHttClient(
    {
      method: 'POST',
      url: '/background_check',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const [
    { loading: updateBackgroundCheckRequestApiLoading },
    updateBackgroundCheckRequestApi,
  ] = useAxiosHttClient(
    {
      method: 'PUT',
      url: '/background_check',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  const loading =
    createBackgroundCheckRequestApiLoading ||
    updateBackgroundCheckRequestApiLoading;

  const handleReasonsChange = (value: string) => {
    if (value !== OTHER_REASON) {
      setOtherReason(undefined);
      setShowOtherReason(false);
    } else {
      setShowOtherReason(true);
    }

    setReason(value);
  };

  const handleOnConfirm = async () => {
    const backgroundCheckRequestApi = backgroundCheckRequest.id
      ? updateBackgroundCheckRequestApi
      : createBackgroundCheckRequestApi;
    try {
      const completedSteps = uniq([
        ...steps,
        BackgroundCheckRequestStepsEnum.Reason,
        BackgroundCheckRequestStepsEnum.CandidateInformation,
      ]);
      const currentStep = BackgroundCheckRequestStepsEnum.CandidateInformation;

      const response = await backgroundCheckRequestApi({
        data: {
          reason: otherReason || reason,
          completed_steps: completedSteps,
          current_step: currentStep,
        },
      });

      const { data: backgroundCheckRequestResponse } = response;

      setBackgroundCheckRequest({
        ...backgroundCheckRequest,
        id: backgroundCheckRequestResponse.data.id,
        reason: backgroundCheckRequestResponse.data.attributes.reason,
      });
      setSteps(completedSteps);
      setActiveStep(currentStep);
      navigate(tabRoutes[currentStep]);
    } catch (error) {
      notification.error({ message: 'Something went wrong' });
    }
  };

  const disableConfirmButton =
    loading || (showOtherReason && !otherReason) || !reason;

  return (
    <>
      <StepTitle>What is the reason for your background check?</StepTitle>
      <Select
        defaultValue={reason}
        value={reason}
        placeholder="Reason for your background check"
        items={REASONS}
        onSelect={handleReasonsChange}
      />

      {showOtherReason && (
        <OtherReasonInputGroup>
          <label>Please specify your reason:</label>
          <Input
            variant={'text'}
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
          />
        </OtherReasonInputGroup>
      )}

      <BackgroundCheckRequestActions firstStep>
        <BackgroundCheckRequestConfirmButton
          variant="primary"
          shape="circle"
          disabled={disableConfirmButton}
          onClick={handleOnConfirm}
        >
          Confirm and Continue
        </BackgroundCheckRequestConfirmButton>
      </BackgroundCheckRequestActions>
    </>
  );
};
