import React, { memo, ReactNode } from 'react';
import { ContainerWrapper } from './styled';

export interface ContainerProps {
  children?: ReactNode;
}

const ContainerComponent: React.FC<ContainerProps> = ({ children }) => {
  return <ContainerWrapper>{children}</ContainerWrapper>;
};

export const Container = memo(ContainerComponent);
