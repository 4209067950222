import { Select as AntdSelect } from 'antd';
import styled, { CSSProp } from 'styled-components';
import { colors } from '../../foundation';

interface SelectElementProps {
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  $errorState?: 'error' | 'warning';
}

const selectSize = ({ size }: SelectElementProps): CSSProp => {
  let css = `
    height: 37px !important;
    font-size: 14px;
  `;

  if (size === 'large') {
    css = `
      height: 46.14px !important;
      font-size: 16px;
    `;
  }

  if (size === 'small') {
    css = `
      height: 31.84px !important;
      font-size: 12px;
    `;
  }

  return css;
};

const selectErrorStyles = ({ $errorState }: SelectElementProps): CSSProp => {
  let css = '';

  if ($errorState) {
    css = `
      border: 1px solid ${colors[$errorState]} !important;
      background-color: ${colors[$errorState + 'Background']} !important;
    `;
  }

  return css;
};

export const SelectWrapper = styled(AntdSelect)`
  width: 100%;
  transition: box-shadow 0.3s ease, border-radius 0.3s ease;
  .ant-select-selector {
    ${selectSize}
    padding: 4px 11px !important;
    border: 0 !important;
    border-radius: 4px !important;
    background-color: ${colors.inputBackground} !important;
    input {
      background-color: ${colors.inputBackground};
    }
    ${selectErrorStyles}
  }
  &.ant-select-focused,
  &:hover {
    .ant-select-selector {
      box-shadow: ${colors.inputHoverBoxShadow} !important;
      background-color: ${colors.white} !important;
    }
  }
`;
