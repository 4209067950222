import styled from 'styled-components';
import { typography } from '../../../stories/foundation';

export const TermsTitle = styled.h1`
  font-size: 50px;
`;

export const TermsContent = styled.div`
  ${typography.p1}
  margin-bottom: 20px;
`;

export const TermsContentSectionHeading = styled.h3`
  font-size: 30px;
`;

export const TermsInnerContent = styled.div`
  ${typography.p1}
`;
