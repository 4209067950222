import React from 'react';
import { useBackgroundCheckRequestSteps } from '../index';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';

interface ProtectedStepProps {
  step: BackgroundCheckRequestStepsEnum;
  children: React.ReactNode;
}

export const ProtectedStep: React.FC<ProtectedStepProps> = ({
  step,
  children,
}) => {
  const [steps] = useBackgroundCheckRequestSteps();

  if (steps.length > 0 && !steps.includes(step)) {
    return <Navigate to={ROUTES.backgroundCheckRequest.reason} />;
  }

  return <div>{children}</div>;
};
