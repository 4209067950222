import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { colors } from '../../foundation';
import {
  devices,
  mediaBreakPointDown,
  mediaBreakPointUp,
} from '../../breakpoints';
const { Header } = Layout;
import { Button } from '../Button';

interface NavbarElementProps {
  fillIn: boolean;
}

interface NavbarLogoProps {
  logoInverted: boolean;
}

export const NavbarWrapper = styled.div<NavbarElementProps>`
  transition: background-color 0.3s ease;
  background-color: ${({ fillIn }) =>
    fillIn ? colors.white : colors.transparent};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
`;

export const NavbarHeader = styled(Header)`
  height: 70px;
  background-color: ${colors.transparent};
  display: flex;
  padding: 0;
`;

export const NavbarMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const NavbarMenu = styled.div`
  ${mediaBreakPointDown(
    'md',
    `
      display: none;
    `
  )}
`;

const navbarMenuItemStyles = css<NavbarElementProps>`
  color: ${({ fillIn }) => (fillIn ? colors.text : colors.white)};
  padding: 22.5px 30px;
  position: relative;
  @media ${devices.maxLaptop} {
    padding: 20px;
  }
`;

const navbarMenuActiveItemStyles = css`
  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 20px;
    bottom: -2px;
    border-radius: 8px;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: ${colors.transparent};
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  &:hover,
  &.active {
    color: ${colors.hyperlinkText};
    &::after {
      border-bottom-color: ${colors.blue500};
    }
  }
`;

export const NavbarMenuItem = styled.span`
  cursor: pointer;
  ${navbarMenuItemStyles}
  ${navbarMenuActiveItemStyles}
`;

export const NavbarButtonItem = styled.span`
  padding: 15px;

  @media ${devices.maxLaptop} {
    padding: 10px;
  }
`;

export const NavbarLinkItem = styled.span`
  a {
    ${navbarMenuItemStyles}
    ${navbarMenuActiveItemStyles}
  }
`;

export const SideNavbarButton = styled(Button)`
  margin-left: auto;
  ${mediaBreakPointUp(
    'md',
    `
      display: none;
    `
  )}
`;

export const NavbarLogo = styled.img<NavbarLogoProps>`
  cursor: pointer;
  width: ${({ logoInverted }) => (logoInverted ? '135px' : '108px')};
  margin: auto 0;
  margin-right: ${({ logoInverted }) => (logoInverted ? '10px' : '23px')};
  ${({ logoInverted }) => (!logoInverted ? 'margin-left: 14px;' : '')};
  ${({ logoInverted }) => (!logoInverted ? 'margin-top: 18px;' : '')};
`;
