import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Form, Divider } from 'antd';
import { Button, FormItemInput } from 'ov-components';
import useAxiosHttClient from '../../../../../config/http_client';
import { useLocalStorage } from '../../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../../utils/constants';
import { notification } from '../../../../../helpers';
import { getErrorMessages } from '../../../../../helpers/error_messages_helpers';
import { CreditCardFormProps, CreditCardFormRef } from './types';
import { CreditCard } from '../../../../../api_types';

interface EditCreditCardFormProps
  extends Omit<CreditCardFormProps, 'refetchCreditCards'> {
  creditCard: CreditCard;
}

const EditCreditCardFormComponent: ForwardRefRenderFunction<
  CreditCardFormRef,
  EditCreditCardFormProps
> = ({ creditCard, onCreditCardSave, closeFormModal }, ref): JSX.Element => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [form] = Form.useForm();

  const [{ loading }, updateCreditCardApi] = useAxiosHttClient(
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  useImperativeHandle(ref, () => ({
    loading,
    clearForm() {
      form.resetFields();
    },
  }));

  const handleSubmit = async (values) => {
    try {
      const editCreditCardApiResponse = await updateCreditCardApi({
        data: values,
        url: `/credit_cards/${creditCard.id}`,
      });
      const editCreditCardApiData = editCreditCardApiResponse.data.data;
      notification.success({
        message: 'Credit Card was successfully updated!',
      });
      onCreditCardSave(editCreditCardApiData.id);
      closeFormModal();
    } catch (error) {
      if (error && error.response && error.response.data) {
        notification.error({
          message: getErrorMessages(error.response.data.errors.details),
        });
      } else {
        notification.error({ message: 'Something went wrong' });
      }
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        card_holder_name: creditCard.attributes.cardholder,
      }}
      name="editCreditCardForm"
      layout={'vertical'}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Cardholder"
        name="card_holder_name"
        rules={[
          {
            required: true,
            message: 'Please enter card holder name!',
          },
        ]}
      >
        <FormItemInput />
      </Form.Item>
      <Divider />
      <div style={{ textAlign: 'right' }}>
        <Button icon={'edit'} shape="circle" loading={loading}>
          Edit Card
        </Button>
      </div>
    </Form>
  );
};

export const EditCreditCardForm = forwardRef(EditCreditCardFormComponent);
