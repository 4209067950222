import { useState } from 'react';

function usePersistedState<T>(key: string, initialValue: T, Storage): any {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = Storage.getItem(key);
      if (item) {
        return typeof item === 'object' ? JSON.parse(item) : item;
      }

      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        Storage.setItem(
          key,
          typeof valueToStore === 'object'
            ? JSON.stringify(valueToStore)
            : valueToStore
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = (): void => {
    Storage.removeItem(key);
  };

  return [storedValue, setValue, deleteItem];
}

export function useLocalStorage<T>(key: string, initialValue: T): any {
  return usePersistedState(key, initialValue, window.localStorage);
}
