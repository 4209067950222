import {
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  DatePicker as AntdDatePicker,
} from 'antd';

import MaskedInput from 'react-text-mask';

import styled, { css, CSSProp } from 'styled-components';
import { colors } from '../../foundation';

interface InputElementProps {
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  $errorState?: 'error' | 'warning';
}

export const InputWrapper = styled.div<InputElementProps>``;

const inputSize = ({ size }: InputElementProps): CSSProp => {
  let css = `
    padding: 7.5px 15px;
    font-size: 14px;
  `;

  if (size === 'large') {
    css = `
      padding: 10.5px 15px;
      font-size: 16px;
    `;
  }

  if (size === 'small') {
    css = `
      padding: 6.5px 11px;
      font-size: 12px;
    `;
  }

  return css;
};

const inputStyles = css`
  ${inputSize}
  color: ${colors.text};
  border: 0;
  border-radius: 4px;
  background-color: ${colors.inputBackground};
`;

const inputErrorStyles = ({ $errorState }: InputElementProps): CSSProp => {
  let css = '';

  if ($errorState) {
    css = `
      border: 1px solid ${colors[$errorState]};
      background-color: ${colors[$errorState + 'Background']} !important;
    `;
  }

  return css;
};

const inputDisabledStyles = css`
  ${({ disabled }) =>
    disabled
      ? `background-color: ${colors.inputDisabledBackground} !important`
      : ''};
`;

const inputFocusStyles = css`
  border-color: ${colors.primaryText};
  box-shadow: ${colors.inputHoverBoxShadow};
  background-color: ${colors.inputHoverBackground};
`;

export const TextInput = styled(AntdInput)<InputElementProps>`
  ${inputStyles}
  ${inputErrorStyles}
  &:disabled {
    ${inputDisabledStyles}
  }
  &:focus,
  &:hover {
    ${inputFocusStyles}
  }
`;

export const NumberInput = styled(AntdInputNumber)<InputElementProps>`
  ${inputStyles}
  ${inputErrorStyles}
  ${inputDisabledStyles}
  width: 100%;
  &.ant-input-number-focused,
  &:hover {
    ${inputFocusStyles}
  }
  input {
    height: auto;
    padding: 0;
  }
`;

export const PasswordInput = styled(AntdInput.Password)<InputElementProps>`
  ${inputStyles}
  ${inputErrorStyles}
  ${inputDisabledStyles}
  &.ant-input-affix-wrapper-focused, &:hover {
    ${inputFocusStyles}
    input {
      background-color: ${colors.inputHoverBackground};
    }
  }
  input {
    ${inputErrorStyles}
    background-color: ${colors.inputBackground};
    &:disabled {
      ${inputDisabledStyles}
    }
  }
`;

export const DatePickerInput = styled(AntdDatePicker)<InputElementProps>`
  ${inputStyles}
  ${inputErrorStyles}
  ${inputDisabledStyles}
  width: 100%;
  &:focus,
  &.ant-picker-focused,
  &:hover {
    ${inputFocusStyles}
  }
`;

export const MaskInput = styled(MaskedInput)<InputElementProps>`
  transition: all 0.3s ease;
  ${inputStyles}
  ${inputErrorStyles}
  ${inputDisabledStyles}
  width: 100%;
  &:focus,
  &.ant-picker-focused,
  &:hover,
  &:focus-visible {
    ${inputFocusStyles}
    outline: none;
  }
`;
