import { devices } from './index';
import { css } from 'styled-components';
import {
  Breakpoint,
  breakpoints,
  nextBreakPoint,
  singleBreakpointFactor,
} from './sizes';

export const cssOnDevice = (deviceType, css) => {
  const device = devices[deviceType];

  return `
    @media ${device} {
      ${css};
    }
  `;
};

export const cssOnMobileDevices = (css) => {
  return cssOnDevice('maxLaptop', css);
};

export const cssOnWeb = (css) => {
  return cssOnDevice('minLaptop', css);
};

export const hideOnWeb = () => {
  return cssOnWeb(css`
    display: none;
  `);
};

export const hideOnMobileAndTabletDevices = () => {
  return cssOnMobileDevices(css`
    display: none;
  `);
};
// TODO: Remove all the above helpers

// These helpers are created to generate media queries similar to Bootstrap mixins
// https://getbootstrap.com/docs/5.0/layout/breakpoints/#media-queries
//
// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
export const mediaBreakPointUp = (breakpoint: Breakpoint, css) => {
  return `
    @media (min-width: ${breakpoints[breakpoint]}px) {
      ${css};
    }
  `;
};

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
export const mediaBreakPointDown = (breakpoint: Breakpoint, css) => {
  return `
    @media (max-width: ${breakpoints[breakpoint]}px) {
      ${css};
    }
  `;
};

export const mediaBreakPointSingle = (breakpoint: Breakpoint, css) => {
  return `
    @media (min-width: ${
      breakpoints[breakpoint]
    }px) and (max-width: ${nextBreakPoint(breakpoint)}px) {
      ${css};
    }
  `;
};

export const mediaBreakPointBetween = (
  firstBreakpoint: Breakpoint,
  secondBreakpoint: Breakpoint,
  css
) => {
  return `
    @media (min-width: ${breakpoints[firstBreakpoint]}px) and (max-width: ${
    breakpoints[secondBreakpoint] - singleBreakpointFactor
  }px) {
      ${css};
    }
  `;
};
