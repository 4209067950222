import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import {
  AMERICAN_EXPRESS_CARD,
  OTHER_CARDS,
  EXPIRY_DATE,
  CVC,
  CARD_REGEX_PATTERNS,
} from './constants';
import {
  stripeCardNumberValidation,
  stripeCardExpiryValidation,
  stripeCardExpiryFormatValidation,
} from './validations';
import { Form, Divider, Col, Row } from 'antd';
import { Button, FormItemInput } from 'ov-components';
import { CardsBrandWrapper } from './styled';
import useAxiosHttClient from '../../../../../config/http_client';
import { useLocalStorage } from '../../../../../utils/hooks/useLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../../../utils/constants';
import { notification } from '../../../../../helpers';
import { getErrorMessages } from '../../../../../helpers/error_messages_helpers';
import { CreditCardBrandsEnum } from '../../../../../api_types/enums';
import { CardIcon } from './helpers';
import { CreditCardFormRef } from './types';

function findDebitCardType(cardNumber) {
  for (const card in CARD_REGEX_PATTERNS) {
    if (cardNumber.replace(/[^\d]/g, '').match(CARD_REGEX_PATTERNS[card]))
      return card as CreditCardBrandsEnum;
  }

  return undefined;
}

type CreditCardFormProps = {
  onCreditCardSave: (id: string) => void;
  refetchCreditCards: () => void;
  closeFormModal: () => void;
};

const CreditCardFormComponent: ForwardRefRenderFunction<
  CreditCardFormRef,
  CreditCardFormProps
> = (
  { onCreditCardSave, refetchCreditCards, closeFormModal },
  ref
): JSX.Element => {
  const [authToken] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [form] = Form.useForm();
  const [cardType, setCardType] = useState<CreditCardBrandsEnum | undefined>();

  const [{ loading }, createCreditCardApi] = useAxiosHttClient(
    {
      url: '/credit_cards',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    { manual: true }
  );

  useImperativeHandle(ref, () => ({
    loading,
    clearForm() {
      form.resetFields();
      setCardType(undefined);
    },
  }));

  const handleSubmit = async (values) => {
    const formData = {
      ...values,
      card_number: values.card_number.replace(/\s/g, ''),
    };

    try {
      const creditCardApiResponse = await createCreditCardApi({
        data: formData,
      });
      const creditCardApiData = creditCardApiResponse.data.data;
      notification.success({
        message: 'Credit Card was successfully added!',
      });
      closeFormModal();
      refetchCreditCards();
      onCreditCardSave(creditCardApiData.id);
    } catch (error) {
      if (error && error.response && error.response.data) {
        notification.error({
          message: getErrorMessages(error.response.data.errors.details),
        });
      } else {
        notification.error({ message: 'Something went wrong' });
      }
    }
  };

  const handleCardType = (e) => {
    setCardType(findDebitCardType(e.target.value));
  };

  return (
    <Form
      form={form}
      name="paymentForm"
      layout={'vertical'}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Cardholder"
        name="card_holder_name"
        rules={[
          {
            required: true,
            message: 'Please enter card holder name!',
          },
        ]}
      >
        <FormItemInput />
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => {
          const cardNumberValue = form.getFieldValue('card_number');

          return (
            <>
              <Form.Item
                label="Card Number"
                name="card_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter card number!',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || stripeCardNumberValidation(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Card number is incorrect!')
                      );
                    },
                  }),
                ]}
              >
                <FormItemInput
                  variant={'mask'}
                  maskPattern={
                    ['37', '34'].includes(
                      cardNumberValue &&
                        cardNumberValue.split('').splice(0, 2).join('')
                    )
                      ? AMERICAN_EXPRESS_CARD
                      : OTHER_CARDS
                  }
                  maskGuide={false}
                  onChange={handleCardType}
                  placeholder={'0000 0000 0000 0000'}
                />
              </Form.Item>
              {cardType && (
                <CardsBrandWrapper>
                  <CardIcon cardType={cardType} />
                </CardsBrandWrapper>
              )}
            </>
          );
        }}
      </Form.Item>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label="Card Expiry"
            name="card_expiry"
            rules={[
              {
                required: true,
                message: 'Please enter card expiry!',
              },
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();

                  if (!stripeCardExpiryFormatValidation(value)) {
                    return Promise.reject(new Error('Date format is invalid!'));
                  }

                  if (!stripeCardExpiryValidation(value)) {
                    return Promise.reject(new Error('Expiry date is invalid!'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FormItemInput
              variant={'mask'}
              maskPattern={EXPIRY_DATE}
              maskGuide={false}
              placeholder={'MM/YY'}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Card CVC"
            name="card_cvc"
            rules={[
              {
                required: true,
                message: 'Please enter card cvc!',
              },
              {
                min: 3,
                message: 'Must be 3 characters!',
              },
            ]}
          >
            <FormItemInput
              variant={'mask'}
              maskPattern={CVC}
              maskGuide={false}
              placeholder={'XXX'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div style={{ textAlign: 'right' }}>
        <Button icon={'plus'} shape="circle" loading={loading}>
          Add Card
        </Button>
      </div>
    </Form>
  );
};

export const CreditCardForm = forwardRef(CreditCardFormComponent);
