import React, { forwardRef, memo, useMemo } from 'react';
import { Icon } from '../Icon';
import { IconName } from '../Icon/icons';
import { Spinner } from '../Spinner';
import { ButtonWrapper, ButtonContent, TextIcon, ButtonText } from './styled';

export type ButtonVariants = 'primary' | 'secondary' | 'icon' | 'danger';

export interface ButtonProps {
  children?: JSX.Element | string;
  disabled?: boolean;
  icon?: IconName;
  iconVariant?: 'small' | 'medium' | 'large' | 'extraLarge';
  iconColor?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonVariants;
  shape?: 'circle' | 'round';
  block?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  size?: number;
  title?: string;
}

const ButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (
  {
    children,
    disabled,
    icon,
    iconVariant,
    iconColor,
    onClick,
    variant = 'primary',
    shape = 'round',
    className,
    type,
    loading,
    size,
    ...props
  },
  ref
) => {
  const renderContent = useMemo(() => {
    const renderIconIfNotIconVariantWhenLoading =
      (variant === 'icon' && !loading) || variant !== 'icon';

    return (
      <ButtonContent variant={variant} $loading={loading}>
        {icon && renderIconIfNotIconVariantWhenLoading && (
          <TextIcon disabled={disabled} hasChildren={!!children}>
            <Icon
              name={icon}
              variant={iconVariant || 'small'}
              fillColor={iconColor}
            />
          </TextIcon>
        )}
        <ButtonText>{children}</ButtonText>
        {loading && <Spinner size="small" />}
      </ButtonContent>
    );
  }, [variant, loading, icon, disabled, children, iconVariant, iconColor]);

  const handleOnClick = (e): void => {
    onClick && onClick(e);
  };

  return (
    <ButtonWrapper
      disabled={disabled || loading}
      onClick={handleOnClick}
      variant={variant}
      shape={shape}
      className={className}
      type={type}
      ref={ref}
      size={size}
      iconVariant={iconVariant}
      {...props}
    >
      {renderContent}
    </ButtonWrapper>
  );
};

export const Button = memo(forwardRef(ButtonComponent));
