import React, { useEffect, useState } from 'react';
import { StepActiveTabTextForMobile, StepsWrapper, StepTabs } from './styled';
import { useNavigate } from 'react-router-dom';
import { tabRoutes, useBackgroundCheckRequestSteps } from '../index';
import { BackgroundCheckRequestStepsEnum } from '../../../../api_types/enums';

export const Steps: React.FC = () => {
  const [steps, , activeStep, setActiveStep, , , disabledSteps] =
    useBackgroundCheckRequestSteps();
  const navigate = useNavigate();
  const [tabAnimation, setTabAnimation] = useState(false);

  const tabItems = [
    {
      label: '1. Reason',
      key: BackgroundCheckRequestStepsEnum.Reason,
      disabled: disabledSteps.includes(BackgroundCheckRequestStepsEnum.Reason),
    },
    {
      label: '2. Candidate Info',
      key: BackgroundCheckRequestStepsEnum.CandidateInformation,
      disabled:
        !steps.includes(BackgroundCheckRequestStepsEnum.CandidateInformation) ||
        disabledSteps.includes(
          BackgroundCheckRequestStepsEnum.CandidateInformation
        ),
    },
    {
      label: '3. Self Declaration',
      key: BackgroundCheckRequestStepsEnum.SelfDeclaration,
      disabled:
        !steps.includes(BackgroundCheckRequestStepsEnum.SelfDeclaration) ||
        disabledSteps.includes(BackgroundCheckRequestStepsEnum.SelfDeclaration),
    },
    {
      label: '4. Payment',
      key: BackgroundCheckRequestStepsEnum.Payment,
      disabled:
        !steps.includes(BackgroundCheckRequestStepsEnum.Payment) ||
        disabledSteps.includes(BackgroundCheckRequestStepsEnum.Payment),
    },
    {
      label: '5. ID Verification',
      key: BackgroundCheckRequestStepsEnum.IdVerification,
      disabled:
        !steps.includes(BackgroundCheckRequestStepsEnum.IdVerification) ||
        disabledSteps.includes(BackgroundCheckRequestStepsEnum.IdVerification),
    },
  ];

  const defaultTabItem = tabItems.find(
    (item) => item.key === BackgroundCheckRequestStepsEnum.Reason
  );
  const activeTabItem = tabItems.find((item) => item.key === activeStep);

  useEffect(() => {
    const disabledTabItems = tabItems.filter((item) =>
      disabledSteps.includes(item.key)
    );
    disabledTabItems.forEach((item) => {
      item.disabled = true;
    });
  }, [tabItems, disabledSteps]);

  const handleOnTabClick = (key: BackgroundCheckRequestStepsEnum) => {
    setActiveStep(key);
    setTabAnimation(true);
    navigate(tabRoutes[key]);
  };

  return (
    <StepsWrapper>
      <StepActiveTabTextForMobile>
        {activeTabItem && activeTabItem.label}
      </StepActiveTabTextForMobile>
      <StepTabs
        animated={tabAnimation}
        defaultActiveKey={defaultTabItem && defaultTabItem.key}
        activeKey={activeTabItem && activeTabItem.key}
        size="large"
        centered
        onTabClick={(key) => handleOnTabClick(key)}
        items={tabItems}
      />
    </StepsWrapper>
  );
};
