import React from 'react';

const InfoIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,16c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4
	c0-0.6,0.4-1,1-1s1,0.4,1,1V16z M11,8c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S11,8.6,11,8z"
    />
  </svg>
);

export default InfoIcon;
