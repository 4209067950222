import styled from 'styled-components';
import {
  mediaBreakPointDown,
  mediaBreakPointSingle,
  mediaBreakPointUp,
} from '../../../stories/breakpoints';
import { colors, typography } from '../../../stories/foundation';
import { Button, Text } from 'ov-components';

export const LandingBanner = styled.div`
  min-height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const LandingBannerShape = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 505px;
  width: 412px;

  ${mediaBreakPointUp(
    'xs',
    `
      height: 635px;
      width: 450px;
    `
  )}

  ${mediaBreakPointUp(
    'md',
    `
      height: 736px;
      width: 809px;
    `
  )}

  ${mediaBreakPointUp(
    'xmd',
    `
      height: 100vh;
      width: 725px;
    `
  )}

  ${mediaBreakPointUp(
    'lg',
    `
      height: 100vh;
      width: 645px;
    `
  )}

  ${mediaBreakPointUp(
    'xxl',
    `
      height: 100vh;
      width: 1015px;
    `
  )}

  ${mediaBreakPointUp(
    'xxxl',
    `
      height: 100vh;
      width: 1255px;
    `
  )}
`;

export const LandingBannerImage = styled.img`
  position: absolute;
  z-index: -1;
  top: 320px;
  right: 20px;
  height: 344px;

  ${mediaBreakPointUp(
    'xs',
    `
      top: 440px;
      right: 20px;
      height: 380px;
    `
  )}

  ${mediaBreakPointUp(
    'md',
    `
      top: 380px;
      height: 525px;
      right: 25px;
    `
  )}

  ${mediaBreakPointUp(
    'xmd',
    `
      top: 72px;
      height: 339px;
      right: 63px;
    `
  )}

  ${mediaBreakPointUp(
    'lg',
    `
      top: 150px;
      height: 516px;
      right: 25px;
    `
  )}

  ${mediaBreakPointUp(
    'xl',
    `
      top: 105px;
      right: 25px;
      height: 670px;
    `
  )}

  ${mediaBreakPointUp(
    'xxxl',
    `
      top: 112px;
      right: 175px;
      height: 820px;
    `
  )}
`;

export const LandingBannerContentBody = styled.div`
  // All Mobile devices under 'xs'
  ${mediaBreakPointDown(
    'xs',
    `
      margin-top: 15px;
      width: 320px;
      margin-right: 10px;
    `
  )}

  // Single
  ${mediaBreakPointSingle(
    'xs',
    `
      margin-top: 25px;
      width: 360px;
      margin-right: 9px;
    `
  )}
`;

export const LandingBannerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  justify-content: space-between;
  align-items: center;

  ${mediaBreakPointUp(
    'xs',
    `
      justify-content: space-between;
      align-items: center;
    `
  )}

  // Ipad Portrait
  ${mediaBreakPointUp(
    'md',
    `
      justify-content: flex-start;
      width: 385px;
      align-items: flex-start;
      margin-top: 80px;
    `
  )}

  ${mediaBreakPointUp(
    'xmd',
    `
      justify-content: center;
      width: 385px;
      align-items: flex-start;
    `
  )}

    // Ipad Landscape
  ${mediaBreakPointUp(
    'lg',
    `
      margin-top: 0;
    `
  )}

  ${mediaBreakPointUp(
    'xxl',
    `
      justify-content: center;
      width: 495px;
      align-items: flex-start;
      margin-top: -70px;
    `
  )}

  ${mediaBreakPointUp(
    'xxxl',
    `
      width: 480px;
      align-items: flex-start;
      margin-top: -55px;
    `
  )}
`;

export const LandingBannerContentTitle = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: ${colors.white};

  // Mobile Portrait
  ${mediaBreakPointUp(
    'xs',
    `
      font-size: 40px;
      line-height: 55px;
    `
  )}

  // Desktop and above
  ${mediaBreakPointUp(
    'xxl',
    `
      font-size: 54px;
      line-height: 65px;
    `
  )}
`;

export const LandingBannerContent = styled.p`
  color: ${colors.white};
  font-weight: 300;
  font-size: 23px;

  // Mobile Portrait
  ${mediaBreakPointUp(
    'xs',
    `
      font-size: 27px;
    `
  )}

  // Desktop and above
  ${mediaBreakPointUp(
    'xxl',
    `
      font-size: 32px;
      line-height: 42px;
    `
  )}
`;

export const LandingBannerButton = styled(Button)`
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  width: 350px;
  height: 50px;
  margin-bottom: 40px;
  background-color: ${colors.primaryText};
  color: ${colors.white};
  &:hover {
    box-shadow: ${colors.inputHoverBoxShadow};
    background-color: ${colors.white};
    color: ${colors.primaryText};
  }

  // Mobile Portrait
  ${mediaBreakPointUp(
    'xs',
    `
      width: 350px;
      height: 50px;
      margin-bottom: 35px;
    `
  )}

  // Ipad Portrait and above
  ${mediaBreakPointUp(
    'md',
    `
      background-color: ${colors.white};
      color: ${colors.primaryText};
      &:hover {
        border: 1px solid ${colors.white};
        background-color: ${colors.primaryText};
        color: ${colors.white};
      }
    `
  )}

    // Mobile Landscape and above
  ${mediaBreakPointUp(
    'xmd',
    `
      width: 295px;
      height: 45px;
    `
  )}

  // Laptop and above
  ${mediaBreakPointUp(
    'lg',
    `
      width: 350px;
      height: 50px;
    `
  )}
`;

export const LandingFeatures = styled.div`
  ${mediaBreakPointUp(
    'lg',
    `
      margin: 100px 0;
    `
  )}
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  ${mediaBreakPointDown(
    'xs',
    `
      flex-direction: column;
    `
  )}
`;

export const LandingFeatureCard = styled.div`
  flex: 0 0 33.3333333333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const LandingFeatureImage = styled.img`
  width: 100px;
  background-color: ${colors.light300};
  border-radius: 50%;
  padding: 1rem;
`;

export const LandingFeatureTitle = styled.div`
  ${typography.h3}
`;

export const LandingFeatureContent = styled.div`
  ${typography.p1}
  line-heigth: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LandingPricing = styled.div`
  display: flex;
  align-items: center;

  ${mediaBreakPointUp(
    'lg',
    `
      margin: 100px 0;
    `
  )}

  ${mediaBreakPointDown(
    'xs',
    `
      flex-direction: column;
    `
  )}
`;

export const LandingPricingImage = styled.img`
  width: 510px;
  z-index: 1;

  ${mediaBreakPointDown(
    'xs',
    `
      width: 350px;
    `
  )}
`;

export const LandingPricingContentWrapper = styled.div`
  background: ${colors.light300};
  height: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: calc(-510px / 2);
  padding-left: calc(510px / 2);
  border-radius: 30px;

  ${mediaBreakPointDown(
    'xs',
    `
      height: 350px;
      padding-left: 0;
      margin-left: 0;
      margin-top: calc(-350px / 2);
      padding-top: 275px;
      padding-bottom: calc(350px / 2);
      border-radius: 30px;
      flex-direction: column;
    `
  )}
`;

export const LandingPricingContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const LandingPricingContentTitle = styled.div`
  color: ${colors.primaryText};
`;

export const LandingPricingContentTitlePrice = styled.div`
  ${typography.h1}
  font-size: 65px;
  line-height: 72px;
`;

export const LandingPricingContentTitlePriceProduct = styled.div`
  ${typography.h2}
`;

export const LandingPricingContentSubtitle = styled.div`
  ${typography.p1}
  font-size: 18px;
`;

export const LandingPricingContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    height: 45px;
    border: 1px solid ${colors.blue500};
  }
`;
