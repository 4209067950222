import React, { useState } from 'react';
import { SignInButtonWrapper } from './styled';

import {
  AuthWrapper as SignUpWrapper,
  AuthContent as SignUpContent,
  AuthContentLeft as SignUpContentLeft,
  AuthContentRight as SignUpContentRight,
  AuthFormContent as SignUpFormContent,
  AuthLinksWrapper as SignUpLinkWrapper,
  PageFullBodyStyle,
} from '../styled';

import { Checkbox, Col, Divider, Form, Row } from 'antd';
import { notification } from '../../../helpers';
import { Button, Container } from 'ov-components';
import { Link } from 'react-router-dom';
import { FormItemInput } from 'ov-components';
import useAxiosHttClient from '../../../config/http_client';
import ThankYou from '../ThankYou';
import { displayDeviseApiFormMessages } from '../../../helpers/error_messages_helpers';

const SignUp = () => {
  const [form] = Form.useForm();
  const [showThankYou, setShowThankYou] = useState(false);

  const [{ loading: signingUp }, executeSignUp] = useAxiosHttClient(
    {
      url: '/sign_up.json',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    },
    { manual: true }
  );
  const submitSignUp = async (values: any) => {
    try {
      await executeSignUp({
        data: {
          user: values,
        },
      });
      setShowThankYou(true);
    } catch (error) {
      displayDeviseApiFormMessages(error, form);
    }
  };

  if (showThankYou) {
    return <ThankYou email={form.getFieldValue('email')} />;
  }

  return (
    <>
      <PageFullBodyStyle />
      <SignUpWrapper>
        <SignUpContent>
          <SignUpContentLeft>
            <Container>
              <SignUpFormContent>
                <h3>Sign Up</h3>
                <h2>Welcome! Please set up your account</h2>
              </SignUpFormContent>
              <Form
                form={form}
                name="signUp"
                layout={'vertical'}
                autoComplete="off"
                onFinish={submitSignUp}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email!' },
                    {
                      pattern: /^\S+@\S+\.\S+$/,
                      message: 'Email is not valid!',
                    },
                  ]}
                >
                  <FormItemInput />
                </Form.Item>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                      ]}
                    >
                      <FormItemInput variant="password" />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Confirm Password"
                      name="password_confirmation"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <FormItemInput variant="password" />
                    </Form.Item>
                  </Col>
                </Row>

                <SignInButtonWrapper>
                  <Row justify="space-between">
                    <Col md={16} xs={24}>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error('Should accept agreement')
                                  ),
                          },
                        ]}
                      >
                        <Checkbox>
                          Accept{' '}
                          <Link to={'/terms'} target="_blank">
                            terms and conditions
                          </Link>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item>
                        <Button
                          variant="primary"
                          shape="circle"
                          disabled={signingUp}
                          block
                        >
                          Confirm and Register
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </SignInButtonWrapper>
              </Form>
              <Divider />
              <SignUpLinkWrapper>
                {'Already have an account? '}
                <Link to={'/login'}>Sign In</Link>
              </SignUpLinkWrapper>
            </Container>
          </SignUpContentLeft>
          <SignUpContentRight />
        </SignUpContent>
      </SignUpWrapper>
    </>
  );
};

export default SignUp;
