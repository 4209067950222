import styled from 'styled-components';
import { BackgroundCheckRequestActions } from '../styled';
import { mediaBreakPointDown } from '../../../../stories/breakpoints';
import { typography } from '../../../../stories/foundation';

export const BackgroundCheckIDVerificationActions = styled(
  BackgroundCheckRequestActions
)`
  justify-content: right;
  flex-direction: row;
  gap: 20px;

  ${mediaBreakPointDown('sm', `flex-direction: column;`)}
  ${mediaBreakPointDown('sm', `flex-direction: column-reverse;`)}
`;

export const VerifyIDVerificationContent = styled.p`
  ${typography.p1}
`;
