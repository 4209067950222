import React from 'react';
import {
  TermsContent,
  TermsTitle,
  TermsInnerContent,
  TermsContentSectionHeading,
} from './styled';

const AboutUs = () => {
  return (
    <>
      <TermsTitle>SCOPE OF THE ONTAB VERIFY TERMS OF USE</TermsTitle>
      <TermsContent>
        These Terms of Use, together with any document expressly incorporated by
        reference (Collectively the “ONTAB Verify Terms of Use” or “ONTAB Verify
        Terms”) govern your use of the background verification services
        (Collectively the “Verify Services”) and its website subsection
        Ontab.com/verify (the “Ontab Verify website”) as provisioned by Ontab
        Verify (referred to herein as “Ontab Verify”, “we”, “us”, or “our”)
      </TermsContent>
      <TermsContentSectionHeading>
        ACCEPTANCE OF THE ONTAB VERIFY TERMS OF USE
      </TermsContentSectionHeading>
      <TermsContent>
        Please read and understand the following Ontab Verify Terms of Use prior
        to using the Ontab Verify Services. The Ontab Verify Terms of Use apply
        in addition to the Ontab Inc.{' '}
        <a href="https://ontab.com/terms/" target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a href="https://ontab.com/privacy/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </TermsContent>
      <TermsContent>
        By using the Ontab Verify website and platform you (the “Client) agree
        to be bound by these terms of use, including that you are of legal age
        and legally competent to enter into this agreement. If you do not agree,
        do not use the ontab.com/verify portion of Ontab website or the Verify
        Services thereto.
      </TermsContent>
      <TermsContentSectionHeading>
        CHANGES TO ONTAB VERIFY OR TERMS OF USE
      </TermsContentSectionHeading>
      <TermsContent>
        Ontab Verify reserves the right, at its sole discretion, to change,
        modify, add or remove portions of these Terms of Use, at any time by
        posting the changes on the Ontab Verify website.
      </TermsContent>
      <TermsContentSectionHeading>
        PROVISION OF ONTAB VERIFY SERVICES
      </TermsContentSectionHeading>
      <TermsContent>
        <ol>
          <li>
            <TermsContent>
              Ontab Verify provides the Client with criminal background check
              and verification services. Upon request and relying upon your
              representations that you have Permissible Purpose to order our
              Services, Ontab Verify will provide background checks,
              verifications, and other consumer reports to you, the Client, when
              available through our Service Providers.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              The Client understands and acknowledges that the ordering and
              delivery of consumer credit reports for the purposes of conducting
              background screening is subject to additional requirements, terms,
              and conditions, which shall be presented for acceptance only upon
              request.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              By using the Verification Services, you acknowledge that we
              collect data as well as consumer information from different
              sources, including, but without limitation, from databases
              maintained by third party service providers, public authorities
              and other consumer reporting agencies, governmental and non
              governmental databases, and other repositories. Such third party
              agencies and information sourced from them, including any
              associated and applicable third party terms thereto, are not
              maintained or controlled by us. As such, we cannot accept any
              liability or guarantee that the information provided from these
              sources is absolutely accurate, complete, up to date, error free
              or comprehensive in breadth or depth.
            </TermsContent>
          </li>
          <li>
            Ontab Verify reserves the right to amend, terminate, or reinstate
            its agreement with third party service providers or sign agreements
            with new service providers at any time.
          </li>
        </ol>
      </TermsContent>
      <TermsContentSectionHeading>
        TERMS OF ONTAB VERIFY SERVICES
      </TermsContentSectionHeading>
      <TermsContent>
        Subject to the Applicable Laws and the specificity of each Service,
        additional terms and requirements may apply to you or some of the
        Services. Where such specific or additional terms and requirements may
        apply to the requested Services, such provisions shall be communicated
        prior to processing your request. Ontab shall provide the information
        Verification Services as requested and logged onto the Platform under
        these terms:
      </TermsContent>
      <TermsContent>
        <ol>
          <li>
            <TermsContent>
              When using the Services, you undertake to comply with any Service
              specific terms, as well as all other conditions set by the
              international law, even if they are not explicitly mentioned in
              these Terms.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              When using the Services, you acknowledge that Ontab has no control
              over the contents of the terms and conditions for using data
              belonging to third party agencies. Such terms and conditions may
              include, but shall not be limited to, credentialing requirements
              and procedures for granting access to consumer credit reports or
              other Services, as may be required by credit bureaus or other
              third-party data suppliers. Any changes to such license terms
              shall be communicated to the Client prior to processing the
              request.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              You agree and acknowledge that our Services are not intended to be
              used as the sole basis for any business decision, nor to relieve
              you of any obligations you may have in complying with the
              Applicable Law.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              Except as otherwise announced on our Website or exclusively
              communicated to Clients, background screening requests
              (“Transaction”) can be logged 24 hours a day, 7 days a week, 365
              days per year. Clients will use the Services through the
              availability of the exposed data access functions of the relative
              Service (i.e. our web Platform) and within the bounds of the
              Permissible Purpose, to log, view and examine results for their
              Transactions. The standard service level criterion for
              availability or our Platform in accessing the Services is set at
              99% and the standard service level performance threshold for
              processing Transactions is set at 98%. Our Website and Platform
              may from time to time require upgrading, modification, maintenance
              or other work which may result in partial or complete
              non-availability of the Service. We will take all reasonable care
              to provide appropriate and timely notification to Clients and
              Visitors for all scheduled maintenance.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              While we make commercially reasonable efforts to ensure continuous
              undisrupted availability of the Services, the time it takes to
              compile, and present various reports (“Turnaround Time”) varies
              between products and geographies. Turnaround Times, support levels
              for committed Transactional volumes and associated service credit
              terms will be defined subject to a preliminary Service Level
              Agreement.
            </TermsContent>
          </li>
          <li>
            <TermsContent>
              <TermsInnerContent>
                Ontab Verify makes every effort to meet A delay in the provision
                of Ontab Verify Services may occur due to, but not limited to,
                the following reasons:
              </TermsInnerContent>
              <TermsInnerContent>
                <ol type={'a'}>
                  <li>
                    Factors outside our reasonable control such as, but not
                    limited to failure in local access facilities, electric
                    power or environmental control systems or third-party
                    downtime, as well as any Force Majeure event or Internet
                    access or related problems beyond the demarcation point of
                    the Platform gateway;
                  </li>
                  <li>Scheduled maintenance;</li>
                  <li>
                    Any voluntary actions or in actions which result in service
                    interruption by the client include, but not limited to, the
                    malfunction or failure of your equipment, telecommunications
                    systems, hardware or software;
                  </li>
                  <li>Detected malicious software;</li>
                  <li>Reported or investigated data breach;</li>
                </ol>
              </TermsInnerContent>
            </TermsContent>
          </li>
        </ol>
      </TermsContent>
      <TermsContentSectionHeading>CONTACT</TermsContentSectionHeading>
      <TermsContent>
        For questions or concerns about these Terms and Conditions, please
        contact:
      </TermsContent>
      <TermsContent>
        Attn: Legal and Compliance
        <br />
        <a href="mailto:compliance@ontab.com">compliance@ontab.com</a>
      </TermsContent>
      <TermsContent>
        Ontab Inc.
        <br />
        10 Dundas St E, Suite 600
        <br />
        Toronto ON
        <br />
        M4J 1S2
      </TermsContent>
    </>
  );
};

export default AboutUs;
