import {
  VisaIcon,
  AmericanExpressIcon,
  MasterCardIcon,
  DiscoverIcon,
  JcbIcon,
  DinersIcon,
} from './brands';

import { CreditCardBrandsEnum } from '../../../../../api_types/enums';

export const CARD_ICONS = {
  [CreditCardBrandsEnum.Visa]: VisaIcon,
  [CreditCardBrandsEnum.MasterCard]: MasterCardIcon,
  [CreditCardBrandsEnum.AmericanExpress]: AmericanExpressIcon,
  [CreditCardBrandsEnum.Discover]: DiscoverIcon,
  [CreditCardBrandsEnum.DinersClub]: DinersIcon,
  [CreditCardBrandsEnum.Jcb]: JcbIcon,
};

export const CARD_REGEX_PATTERNS = {
  [CreditCardBrandsEnum.Visa]: /^4[0-9]{2,}$/,
  [CreditCardBrandsEnum.MasterCard]: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
  [CreditCardBrandsEnum.AmericanExpress]: /^3[47][0-9]{5,}$/,
  [CreditCardBrandsEnum.Discover]: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
  [CreditCardBrandsEnum.DinersClub]: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
  [CreditCardBrandsEnum.Jcb]: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
};

export const OTHER_CARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const AMERICAN_EXPRESS_CARD = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const EXPIRY_DATE = [/[0-9]/, /\d/, '/', /\d/, /\d/];

export const CVC = [/[0-9]/, /\d/, /\d/];
