import React from 'react';

const CloseIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M7.1,7.1c-0.4,0.4-0.4,1,0,1.4l3.5,3.5l-3.5,3.5c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l3.5-3.5l3.5,3.5
	c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L13.4,12l3.5-3.5c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6L8.5,7.1C8.1,6.7,7.4,6.7,7.1,7.1z"
    />
  </svg>
);

export default CloseIcon;
