import React from 'react';

const FacebookIcon = ({ className, style }): React.ReactElement => (
  <svg
    className={className}
    style={{ ...style, 'enableBackground:': 'new 0 0 24 24' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect style={{ 'fill': 'none' }} width="24" height="24" />
    <path
      className="st1"
      d="M2,7c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V7z M4,8.2V16c0,0.6,0.4,1,1,1
	h14c0.6,0,1-0.4,1-1V8.2l-8,5L4,8.2z M18.1,7H5.9l6.1,3.8L18.1,7z"
    />
  </svg>
);

export default FacebookIcon;
