import styled, { CSSProp, css } from 'styled-components';
import { colors, typography } from '../../foundation';

type ButtonWrapperProps = {
  disabled?: boolean;
  variant?: string;
  shape?: string;
  size?: number;
  marginRight?: string;
  marginLeft?: string;
  iconVariant?: string;
  block?: boolean;
};

export const disabledButton = css`
  background-color: ${colors.disabledButtonBackground};
  color: ${colors.disabledButtonLabel};
  cursor: default;
  pointer-events: none;

  &:hover {
    background-color: ${colors.disabledButtonBackground};
  }

  &:focus {
    background-color: ${colors.disabledButtonBackground};
  }
`;

export const primaryButton = css`
  color: ${colors.white};
  path {
    fill: ${colors.white};
  }
  background-color: ${colors.primaryButtonBackground};

  &:hover {
    background-color: ${colors.primaryButtonHover};
  }

  &:focus {
    background-color: ${colors.primaryButtonHover};
  }
`;

export const secondaryButton = css`
  color: ${colors.secondaryButtonLabel};
  background-color: ${colors.secondaryButtonBackground};

  &:hover {
    background-color: ${colors.secondaryButtonHover};
  }

  &:focus {
    background-color: ${colors.secondaryButtonHover};
  }
`;

export const dangerButton = css`
  color: ${colors.dangerButtonLabel};
  background-color: ${colors.dangerButtonBackground};

  &:hover {
    background-color: ${colors.dangerButtonHover};
  }

  &:focus {
    background-color: ${colors.dangerButtonHover};
  }

  ${({ disabled }: ButtonWrapperProps): CSSProp | false =>
    !!disabled && disabledButton}
`;

const disabledIconButton = css`
  background-color: ${colors.iconButtonBackground} !important;

  svg {
    color: ${colors.iconButtonLabelDisabled};
  }
`;

const iconButton = css`
  height: ${({ size }: ButtonWrapperProps): CSSProp =>
    size ? `${size}px` : '40px'};
  width: ${({ size }: ButtonWrapperProps): CSSProp =>
    size ? `${size}px` : '40px'};
  padding: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.iconButtonLabel};
  background-color: transparent;

  &:hover {
    background-color: ${colors.iconButtonHover};
  }

  &:focus {
    background-color: ${colors.iconButtonHover};
  }

  ${({ disabled }: ButtonWrapperProps): CSSProp | false =>
    !!disabled && disabledIconButton}
`;

type ButtonContentProps = {
  disabled?: boolean;
  variant?: string;
  loading?: boolean;
};

const loadingButtonContent = css`
  position: relative;

  > span {
    opacity: 0;
  }

  > div {
    &:last-child {
      margin-top: -3px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ButtonContent = styled.div<{
  variant: string;
  $loading?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  > svg {
    ${({ variant }: ButtonContentProps): CSSProp | false =>
      variant !== 'icon' && 'margin-right: 2px;'}
  }

  ${({ $loading }): CSSProp => ($loading ? loadingButtonContent : '')}
`;

const variants = {
  primary: primaryButton,
  secondary: secondaryButton,
  danger: dangerButton,
  icon: iconButton,
};

const buttonSizing = ({ iconVariant }: ButtonWrapperProps): CSSProp => {
  let css = '';
  if (iconVariant) {
    css += `padding: 10px;`;
  }

  if (iconVariant === 'small') {
    css += `height: 35px;`;
  } else if (iconVariant === 'medium') {
    css += `height: 41px;`;
  } else if (iconVariant === 'large') {
    css += `height: 50px;`;
  } else if (iconVariant === 'extraLarge') {
    css += `height: 60px;`;
  } else {
    css += `
      padding: 10px 16px;
      height: 40px;
    `;
  }

  return css;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${buttonSizing}
  ${({ shape }) => `border-radius: ${shape === 'circle' ? '35px' : '4px'};`}
  ${({ block }) => block && `width: 100%;`}
  border: none;
  cursor: pointer;
  ${typography.p2Medium}

  &:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  ${({ variant }): CSSProp => variant && variants[variant]}
  ${({ disabled }): CSSProp | false => !!disabled && disabledButton}
`;

export const ButtonText = styled.span`
  white-space: nowrap;
`;

type TextIconProps = {
  disabled?: boolean;
  hasChildren?: boolean;
};

export const TextIcon = styled.div`
  display: flex;
  ${({ hasChildren }: TextIconProps): string | false | undefined =>
    hasChildren && 'margin-right: 2px;'}

  > svg {
    path {
      ${({ disabled }: TextIconProps): string | false | undefined =>
        disabled && `fill: ${colors.iconButtonLabelDisabled}`}
    }
  }
`;
