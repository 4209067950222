import React from 'react';
import styled from 'styled-components';
import { colors } from '../../stories/foundation';
import { ContainerWrapper } from '../../stories/atoms/Container/styled';

export const AppWrapper = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
`;

export const AppBody = styled.div`
  min-height: calc(100vh - 68px);
  margin-top: 68px;
  padding: 3.5em 0;
  background-color: ${colors.transparent};
`;

export const AppContainer = styled(ContainerWrapper)`
  min-height: inherit;
`;

export const OntabLogo = ({
  className,
  style,
  bgColor = '#FFFFFF',
}): React.ReactElement => (
  <svg
    className={className}
    style={style}
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1500 270.28"
  >
    <g id="Layer_1-2">
      <g>
        <g>
          <path
            style={{ 'fill': bgColor }}
            d="M680.82,134.19c0,17.71-2.89,33.89-8.68,48.53-5.79,14.65-13.82,27.19-24.1,37.62-10.28,10.43-22.5,18.5-36.67,24.19-14.17,5.69-29.54,8.54-46.1,8.54s-31.88-2.85-45.95-8.54c-14.07-5.69-26.25-13.75-36.52-24.19-10.28-10.43-18.31-22.97-24.1-37.62-5.79-14.65-8.68-30.83-8.68-48.53s2.89-34.09,8.68-48.53c5.79-14.44,13.82-26.72,24.1-36.84,10.28-10.12,22.45-17.91,36.52-23.4,14.07-5.48,29.38-8.22,45.95-8.22s31.93,2.74,46.1,8.22c14.17,5.48,26.39,13.28,36.67,23.4,10.28,10.12,18.31,22.4,24.1,36.84,5.79,14.44,8.68,30.62,8.68,48.53Zm-50.78,0c0-9.69-1.58-18.76-4.74-27.19-3.16-8.43-7.54-15.65-13.12-21.66-5.59-6.01-12.33-10.75-20.24-14.23-7.9-3.48-16.6-5.22-26.08-5.22s-18.13,1.74-25.93,5.22c-7.8,3.48-14.54,8.22-20.24,14.23-5.69,6.01-10.07,13.23-13.12,21.66-3.06,8.43-4.58,17.5-4.58,27.19s1.58,19.44,4.74,27.98c3.16,8.54,7.53,15.86,13.12,21.97,5.58,6.12,12.28,10.91,20.08,14.39,7.8,3.48,16.44,5.22,25.93,5.22s18.13-1.74,25.93-5.22c7.8-3.48,14.54-8.27,20.24-14.39,5.69-6.11,10.12-13.44,13.28-21.97,3.16-8.54,4.74-17.86,4.74-27.98Z"
          />
          <path
            style={{ 'fill': bgColor }}
            d="M856.73,247.07l-90.11-146.39h-.95l1.27,146.39h-47.01V23.21h56.18l89.79,146.07h.95l-1.27-146.07h45.57V247.07h-54.43Z"
          />
          <path
            style={{ 'fill': bgColor }}
            d="M1044.95,247.07h-29.74V50.72h-75.21V23.21h180.15v27.51h-75.21V247.07Z"
          />
          <path
            style={{ 'fill': bgColor }}
            d="M1138.66,247.07h-33.04l92.21-223.85h27.03l91.31,223.85h-33.64l-24.32-62.06h-95.51l-24.05,62.06Zm108.54-90.18l-36.76-97.31-37.03,97.31h73.79Z"
          />
          <path
            style={{ 'fill': bgColor }}
            d="M1350.12,23.21h73.29c8.01,0,16.02,.95,24.03,2.85,8.01,1.9,15.17,5.06,21.48,9.49,6.31,4.43,11.41,10.23,15.32,17.39,3.9,7.17,5.86,16.12,5.86,26.87,0,12.44-3.41,22.87-10.21,31.3-6.81,8.43-15.82,14.23-27.03,17.39v.63c6.8,.84,13.11,2.69,18.92,5.53,5.8,2.85,10.81,6.59,15.02,11.23,4.21,4.64,7.46,10.12,9.76,16.44,2.3,6.32,3.45,13.39,3.45,21.18,0,10.75-2.1,20.13-6.31,28.14-4.21,8.01-9.91,14.6-17.12,19.76-7.21,5.17-15.67,9.07-25.38,11.7-9.71,2.64-20.08,3.95-31.09,3.95h-69.99V23.21Zm29.74,94.54h38.75c5.6,0,10.96-.68,16.07-2.06,5.1-1.37,9.56-3.48,13.37-6.32,3.8-2.85,6.8-6.43,9.01-10.75,2.2-4.32,3.3-9.54,3.3-15.65,0-12.01-4.2-20.6-12.61-25.77-8.41-5.16-19.73-7.75-33.94-7.75h-33.94V117.75Zm0,102.76h37.25c6.2,0,12.47-.58,18.77-1.74,6.31-1.16,12.01-3.21,17.12-6.17,5.11-2.95,9.21-6.9,12.31-11.86,3.1-4.95,4.66-11.22,4.66-18.81,0-13.28-4.31-22.87-12.91-28.77-8.61-5.9-21.03-8.85-37.25-8.85h-39.95v76.2Z"
          />
        </g>
        <path
          style={{ 'fill': bgColor }}
          d="M321.57,0H38.3C17.15,0,0,17.15,0,38.3v23.72c0,6.31,5.11,11.42,11.42,11.42s11.42-5.11,11.42-11.42v-23.72c0-8.54,6.93-15.47,15.47-15.47H321.57c8.54,0,15.47,6.93,15.47,15.47v46.9l-45.35-45.35c-4.54-4.54-11.9-4.54-16.44,0-4.54,4.54-4.54,11.9,0,16.44l33.84,33.84H26.98c-14.9,0-26.98,12.08-26.98,26.98v114.87c0,21.15,17.15,38.3,38.3,38.3H321.57c21.15,0,38.3-17.15,38.3-38.3V38.3c0-21.15-17.15-38.3-38.3-38.3Zm0,247.45H38.3c-8.54,0-15.47-6.93-15.47-15.47V127.19c0-7.86,6.37-14.23,14.23-14.23H309.05l-33.57,33.57c-4.52,4.52-4.87,11.95-.42,16.55,4.52,4.69,12,4.74,16.59,.16l45.38-45.38v114.12c0,8.54-6.93,15.47-15.47,15.47Z"
        />
      </g>
    </g>
  </svg>
);
