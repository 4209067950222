import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../foundation';

interface LoaderProps {
  size: 'small' | 'medium' | 'large';
}

interface ContainerProps {
  placement?: string;
}

const rotate = keyframes`
100%{
  transform: rotate(360deg);
 }`;

const dash = keyframes`
 0%{
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
 }
 50%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -35;
 }
 100%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -68;
 }`;

const dashLarge = keyframes`
 0%{
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
 }
 50%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -35;
 }
 100%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -90;
 }`;

const sizes = {
  large: { size: '32px', animation: dashLarge },
  medium: { size: '24px', animation: dash },
  small: { size: '16px', animation: dash },
};

const color = keyframes`
 10%{
  stroke: ${colors.blue500};
}
43%{
  stroke: ${colors.ancientBlue600};
}
76%{
  stroke: ${colors.green600};
}`;
export const Loader = styled.div<LoaderProps>`
  width: ${({ size }) => sizes[size].size};
  height: ${({ size }) => sizes[size].size};
  left: 50%;
  top: 20%;
  margin: 0 auto 0 auto;
  > svg {
    animation: ${rotate} 2s linear infinite;
    height: ${({ size }) => sizes[size].size};
    position: relative;
    width: ${({ size }) => sizes[size].size};
    > circle {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: ${({ size }) => sizes[size].animation} 1.5s ease-in-out
          infinite,
        ${color} 4.5s ease-in-out infinite;
      stroke: ${colors.blue500};
      stroke-linecap: round;
    }
  }
`;

export const LoaderContainer = styled.div<ContainerProps>`
  display: inline-block;

  ${({ placement }) =>
    placement &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    `}
`;
